/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid, Card } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ReportsBarChart from "templates/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "templates/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "templates/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// Dashboard components
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "App";
import { DashboardAPI } from "APIs/DashboardAPI";
import MDTypography from "components/MDTypography";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState();
  const userData = useContext(UserContext);
  const getDashBoardFunc = async (access_token) => {
    const response = await DashboardAPI(access_token);
    console.log(response);
    setDashboardData({ ...response });
  };

  useEffect(() => {
    if (userData?.access_token !== undefined) {
      getDashBoardFunc(userData?.access_token);
    }
  }, [userData]);
  return (
    <>
      <MDBox py={3}>
        <Grid container spacing={2} className="d-flex justify-content-center">
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Media"
                count={dashboardData?.media?.total}
                percentage={{
                  amount: dashboardData?.media?.lastMonth,
                  label: " last month added",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Audio"
                count={dashboardData?.audio?.total}
                percentage={{
                  amount: dashboardData?.audio?.lastMonth,
                  label: "last month added",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="store"
                title="Blog"
                count={dashboardData?.blog?.total}
                percentage={{
                  amount: dashboardData?.blog?.lastMonth,
                  label: "last month added",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ComplexStatisticsCard
                    icon="account_balance"
                    title="seva"
                    count={dashboardData?.seva?.total}
                    percentage={{
                      label: "Number of seva",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ComplexStatisticsCard
                    icon="account_balance"
                    title="literature"
                    count={dashboardData?.literature?.total}
                    percentage={{
                      label: "Number of literature",
                    }}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="primary"
                  title="Devotees"
                  description="Devotees chat for last 12 months"
                  date={`Total: ${dashboardData?.devotee?.total}  , Last Month : ${dashboardData?.devotee?.lastMonth}`}
                  chart={{
                    labels: dashboardData?.devotee?.last12Months?.map(
                      (item) => `${item.mm}-${item.yyyy}`
                    ),
                    datasets: {
                      label: "devotees",
                      data: dashboardData?.devotee?.last12Months?.map((item) => Number(item.count)),
                    },
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Events"
                  description="Event chat for last 12 months"
                  date={`Total: ${
                    Number(dashboardData?.event?.upComming) +
                    Number(dashboardData?.event?.endded) +
                    dashboardData?.event?.running.length
                  }`}
                  chart={{
                    labels: dashboardData?.event?.last12Months.map(
                      (item) => `${item?.mm}-${item?.yyyy}`
                    ),
                    datasets: {
                      label: "Events",
                      data: dashboardData?.event?.last12Months?.map((item) => Number(item.count)),
                    },
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox className=" w-100 d-flex justify-content-center">
          <Card className=" ps-5 pe-5 mb-3">
            <MDTypography variant="h4" fontWeight="medium">
              On Going Events
            </MDTypography>
          </Card>
        </MDBox>
        <MDBox>
          <Grid container spacing={3} className="d-flex justify-content-center">
            {dashboardData?.event?.running?.map((item, key) => {
              return (
                <Grid item xs={12} md={6} key={key}>
                  <OrdersOverview event={item} />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Dashboard;
