import axios from "axios";
import { URL } from "./MainAPILink";
import { notificationHandler } from "reducers/notificationSlice";
import CatchMessage from "./catchMessage";

const GetBlogsAPI = async (access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "blog", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

const DeleteBlog = async (data, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "blog", {
      data: data, // Use 'data' instead of 'body' for DELETE requests
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("BLog Deleted !", "success", dispatch);
    return response.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

const AddNewBlog = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "blog", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("new blog added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const uploadImageBlog = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "blog/upload", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // notificationHandler("Media Uploaded", "success", dispatch);
    return response.data.is_success;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const GetBlogDetails = async (blog_id, access_token, dispatch) => {
  try {
    const response = await axios.get(URL + `blog?blog_id=${blog_id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const EditBlogApi = async (data, access_token, dispatch) => {
  try {
    const response = await axios.put(URL + "blog", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (response.data.status === 200) {
      notificationHandler("Blog Updated!!", "success", dispatch);
      return true;
    }
    return false;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};

export { GetBlogsAPI, AddNewBlog, DeleteBlog, uploadImageBlog, GetBlogDetails, EditBlogApi };
