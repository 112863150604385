import Select from "react-select";
import PropTypes from "prop-types";

export const SelectDropDown = ({ currentSelected, setCurrentSelected, data, defaultOptions }) => {
  return (
    <Select
      cacheOptions
      defaultOptions
      options={data}
      onChange={(selected) => {
        setCurrentSelected(selected);
      }}
      value={currentSelected}
      defaultValue={defaultOptions || { value: 0, label: "Select Country Code" }}
      styles={{
        control: (base) => ({
          ...base,
          fontSize: "15px",
        }),
        menu: (base) => ({
          ...base,
          fontSize: "15px",
        }),
      }}
    />
  );
};

SelectDropDown.propTypes = {
  defaultOptions: PropTypes.shape({
    value: PropTypes.number,
    lable: PropTypes.string,
  }),
  currentSelected: PropTypes.shape({
    value: PropTypes.number,
    lable: PropTypes.string,
  }),
  setCurrentSelected: PropTypes.func,
  data: PropTypes.func,
};
