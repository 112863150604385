import { UserContext } from "App";

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import Donation from "./donations";
import { UploadSeva } from "APIs/SevaAPIs";
import { AddNewSeva } from "APIs/SevaAPIs";
import { GetSevaDetails } from "APIs/SevaAPIs";
import { EditSevaApi } from "APIs/SevaAPIs";
import { popUp } from "components/Custom/AreYouSurePopUp";
const EditSeva = () => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [sevaData, setSevaData] = useState({});
  const [donationData, setDonationData] = useState([
    {
      donation_title: "",
      donation_amount: "",
    },
  ]);
  const [photo, setPhoto] = useState([]);
  const [video, setVideo] = useState([]);
  const getSevaDetailsFunc = async (id, access_token, dispatch) => {
    const response = await GetSevaDetails(id, access_token, dispatch);
    if (response !== null) {
      setSevaData({
        seva_id: response.seva_id,
        seva_title: response.seva_title,
        seva_description: response.seva_description,
      });
      setDonationData([...response.donation_list]);
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getSevaDetailsFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);

  const handleSevaDataChange = (field) => {
    setSevaData((prev) => {
      return { ...prev, [field.target.name]: field.target.value };
    });
  };
  const handleDonationDataChange = (field, key) => {
    const tempData = donationData;
    tempData[key] = { ...tempData[key], [field.target.name]: field.target.value };
    setDonationData([...tempData]);
  };
  const addDonation = () => {
    setDonationData([
      ...donationData,
      {
        donation_title: "",
        donation_amount: "",
      },
    ]);
  };
  const deleteDonation = (key) => {
    let temp = donationData;
    temp.pop(key);
    setDonationData([...temp]);
  };
  const handleMediaUpload = async (seva_id, files, media_type) => {
    const formData = new FormData();
    formData.append("seva_id", seva_id);
    formData.append("media_type", media_type);
    for (var i in files) {
      formData.append("files", files[i]);
    }
    const uploadResponse = await UploadSeva(formData, userData.access_token, dispatch);
    return uploadResponse;
  };
  const handleOnPhotoChange = (e) => {
    setPhoto(e.target.files);
  };
  const handleOnVideoChange = (e) => {
    setVideo(e.target.files);
  };
  const handleOnSubmit = async (form) => {
    var sendData = sevaData;
    sendData.donation = donationData;
    console.log(sendData);
    const editResponse = await EditSevaApi(sendData, userData.access_token, dispatch);
    if (editResponse !== null) {
      if (photo.length > 0) {
        const photoUpload = await handleMediaUpload(editResponse.seva_id, photo, "1");
      }
      if (video.length > 0) {
        var videoUpload = await handleMediaUpload(editResponse.seva_id, video, "2");
      }
      navigate("/seva/view/" + editResponse.seva_id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={9}>
        <Card>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await popUp(handleOnSubmit);
            }}
          >
            <Grid container spacing={2} px={3} pt={2}>
              <Grid item xs={12}>
                <MDTypography variant="h2">Edit Seva</MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <MDTypography>Title</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  name="seva_title"
                  type="text"
                  value={sevaData?.seva_title}
                  fullWidth={true}
                  onChange={handleSevaDataChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                className="d-flex justify-content-start align-items-center"
              >
                <MDTypography>Description</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <div style={{ width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={
                      sevaData.seva_description == undefined
                        ? "enter text"
                        : sevaData?.seva_description
                    }
                    onChange={(event, editor) => {
                      handleSevaDataChange({
                        target: { name: "seva_description", value: editor.getData() },
                      });
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <MDTypography fontSize={30} variant="inherit" className="ms-3" p={1}>
                  Donation
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Donation
                  data={donationData}
                  handleDataChange={handleDonationDataChange}
                  deleteDonation={deleteDonation}
                />
                <div className="d-flex justify-content-end pt-3">
                  <MDButton color={sidenavColor} onClick={addDonation}>
                    Add Donation
                  </MDButton>
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <MDTypography>Upload photo </MDTypography>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <MDBox className="d-flex justify-content-start align-items-center">
                  <input type="file" accept="image/*" multiple onChange={handleOnPhotoChange} />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <MDTypography>Upload video(optional) </MDTypography>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <MDBox className="d-flex justify-content-start align-items-center">
                  <input type="file" accept="video/*" multiple onChange={handleOnVideoChange} />
                </MDBox>
              </Grid>
              <Grid item xs={12} align="end" my={3} mx={3}>
                <MDButton type="submit" color="success">
                  Save Seva
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditSeva;
