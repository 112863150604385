import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "App";
import MDTypography from "components/MDTypography";
import DataTable from "templates/Tables/DataTable";
import { GetMediaByAlbums } from "APIs/AudioAPIs";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import { DeleteAudio } from "APIs/AudioAPIs";
import { useMaterialUIController } from "context";
import { UploadAudio } from "APIs/AudioAPIs";
import { notificationHandler } from "reducers/notificationSlice";
import { popUp } from "components/Custom/AreYouSurePopUp";
const EditAudio = () => {
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [audioData, setAudioData] = useState([]);
  const [file, setFile] = useState({});

  const getAudiosFunc = async (id, access_token, dispatch) => {
    const response = await GetMediaByAlbums(id, access_token, dispatch);
    if (response !== null) {
      setAudioData([...response]);
    }
  };

  const handleDelete = async (audio_id) => {
    const response = await DeleteAudio(audio_id, userData.access_token, dispatch);
    if (response !== null) {
      await getAudiosFunc(id, userData.access_token, dispatch);
    }
  };

  const handleFileChange = (e) => {
    setFile([...e.target.files]);
  };
  const uploadFile = async (e) => {
    e.preventDefault();
    if (Object.keys(file).length > 0) {
      let formData = new FormData();
      formData.append("audio_album_id", id);
      for (var i in file) {
        formData.append("files", file[i]);
      }
      const uploadMedia = await UploadAudio(formData, userData.access_token, dispatch);
      if (uploadMedia !== null) {
        notificationHandler("Audio Album Edited", "success", dispatch);
        navigate("/audio/view/" + id);
      }
    } else {
      notificationHandler("Audio Album Edited", "success", dispatch);
      navigate("/audio/view/" + id);
    }
  };

  useEffect(() => {
    if (userData.access_token !== null) {
      getAudiosFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={uploadFile}>
          <Card>
            {audioData.length > 0 ? (
              <Grid container spacing={2} py={2} px={4}>
                <Grid item xs={12}>
                  <MDTypography variant="h1">{audioData[0]?.audio_album_title}</MDTypography>
                </Grid>

                <Grid item xs={12}>
                  <DataTable
                    table={{
                      rows: [
                        ...audioData?.map((item, key) => {
                          return {
                            title: <MDTypography variant="body2">{item?.audio_title}</MDTypography>,
                            audio: (
                              <audio controls>
                                <source src={item?.audio_location} />
                              </audio>
                            ),
                            actions: (
                              <MDButton
                                color="error"
                                onClick={async (e) => {
                                  await popUp(handleDelete, item.audio_id);
                                }}
                              >
                                Delete
                              </MDButton>
                            ),
                          };
                        }),
                      ],
                      columns: [
                        { Header: "title", accessor: "title", align: "center" },
                        { Header: "audio", accessor: "audio", align: "center" },
                        { Header: "actions", accessor: "actions", align: "center" },
                      ],
                    }}
                    isSorted={false}
                    showTotalEntries={false}
                    noEndBorder
                    // canSearch={true}
                    entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
                    // pagination={{ variant: "contained", color: sidenavColor }}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <div className="pt-5">Upload Audio</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={9}
                  className="d-flex justify-content-start align-items-center"
                  style={{ position: "relative" }}
                >
                  <div className="pt-5">
                    <input
                      type="file"
                      name="image"
                      multiple
                      accept="audio/*"
                      onChange={handleFileChange}
                      //   style={{ opacity: 0, position: "absolute", zIndex: 1, width: "150px" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} align="end">
                  <MDButton color="success" type="submit">
                    Save Audio
                  </MDButton>
                </Grid>
              </Grid>
            ) : (
              <div className="d-flex justify-content-center align-item-center h-100">
                <span className="spinner-grow" role="status"></span>
              </div>
            )}
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default EditAudio;
