import axios from "axios";
import { URL } from "./MainAPILink";
import { notificationHandler } from "reducers/notificationSlice";
import CatchMessage from "./catchMessage";

const GetAllAudioAlbums = async (access_token) => {
  try {
    const response = await axios.get(URL + "audio/album", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const GetMediaByAlbums = async (data, access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "audio?audio_album_id=" + data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const CreateMediaByAlbums = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(
      URL + "audio/album",
      { audio_album_title: data },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    notificationHandler("New Audio Album Added!", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const UploadAudio = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "audio", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

const DeleteAudioAlbum = async (data, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "audio/album?audio_album_id=" + data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Audio Album deleted ", "success", dispatch);
    return true;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};

const DeleteAudio = async (data, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "audio?audio_id=" + data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Audio Album deleted ", "success", dispatch);
    return true;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
export {
  GetAllAudioAlbums,
  GetMediaByAlbums,
  CreateMediaByAlbums,
  UploadAudio,
  DeleteAudioAlbum,
  DeleteAudio,
};
