import axios from "axios";
import { URL } from "./MainAPILink";
export const DashboardAPI = async (access_token) => {
  try {
    const response = await axios.get(URL + "dashboard", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
