import React from "react";
import { Grid, Card } from "@mui/material";

import PropTypes from "prop-types";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "templates/Tables/DataTable";
import MDBox from "components/MDBox";
import { popUp } from "components/Custom/AreYouSurePopUp";
const SevaDonation = ({ data, handleDataChange, deleteDonation }) => {
  return (
    <>
      <Card>
        <MDBox>
          {" "}
          <DataTable
            table={{
              rows: [
                ...data.map((item, key) => {
                  return {
                    title: (
                      <MDInput
                        required
                        name="donation_title"
                        type="text"
                        label="title"
                        value={item.donation_title}
                        onChange={(e) => {
                          handleDataChange(e, key);
                        }}
                      />
                    ),
                    amount: (
                      <MDInput
                        required
                        name="donation_amount"
                        type="number"
                        value={item.donation_amount}
                        onChange={(e) => {
                          handleDataChange(e, key);
                        }}
                      />
                    ),

                    action: (
                      <MDButton
                        color="error"
                        onClick={async () => {
                          await popUp(deleteDonation, key);
                        }}
                      >
                        delete
                      </MDButton>
                    ),
                  };
                }),
              ],
              columns: [
                { Header: "title", accessor: "title", align: "center", width: "25%" },
                { Header: "amount", accessor: "amount", align: "center", width: "20%" },
                { Header: "action", accessor: "action", align: "center", width: "10%" },
              ],
            }}
            isSorted={false}
            showTotalEntries={false}
            noEndBorder
            // canSearch={true}
            entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
            // pagination={{ variant: "contained", color: sidenavColor }}
          />
        </MDBox>
      </Card>
    </>
  );
};
SevaDonation.propTypes = {
  data: PropTypes.array,
  handleDataChange: PropTypes.func,
  deleteDonation: PropTypes.func,
};

export default SevaDonation;
