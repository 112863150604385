import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "templates/Tables/DataTable";
import { Grid, Card } from "@mui/material";
import { UserContext } from "App";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { useMaterialUIController } from "context";
import { DeleteLiteratures } from "APIs/LiteratureAPI";
import { GetAllLiteratures } from "APIs/LiteratureAPI";
import { popUp } from "components/Custom/AreYouSurePopUp";
const Literature = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const [literatures, setLiteratures] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const getAllLiteraturesFunc = async (access_token, dispatch) => {
    const response = await GetAllLiteratures(access_token, dispatch);
    if (response !== null) {
      setLiteratures([...response]);
    }
  };
  const handleDelete = async (id) => {
    const response = await DeleteLiteratures(id, userData.access_token, dispatch);
    if (response !== null) {
      getAllLiteraturesFunc(userData.access_token, dispatch);
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getAllLiteraturesFunc(userData.access_token, dispatch);
    }
  }, [userData]);
  useEffect(() => {
    setRows([
      ...literatures.map((item, key) => {
        return {
          sr: `${key + 1}`,

          title: item.literature_title,
          status: (
            <MDBadge
              badgeContent={item.status == 0 ? "INACTIVE" : "ACTIVE"}
              size="sm"
              color={item.status == 0 ? "secondary" : "success"}
              variant="contained"
              container
            />
          ),
          action: (
            <>
              <MDButton
                className="me-2"
                color={sidenavColor}
                onClick={() => {
                  navigate(`edit/${item.literature_id}`);
                }}
              >
                Edit
              </MDButton>
              <a target="blank" href={item.literature_location}>
                <MDButton className="me-2" color={sidenavColor}>
                  view
                </MDButton>
              </a>
              <MDButton
                color="error"
                onClick={async () => {
                  await popUp(handleDelete, item.literature_id);
                }}
              >
                Delete
              </MDButton>
            </>
          ),
        };
      }),
    ]);
    setColumns([
      { Header: "sr no", accessor: "sr", align: "center", width: "10%" },

      { Header: "title", accessor: "title" },
      { Header: "status", accessor: "status", align: "center" },

      { Header: "action", accessor: "action", align: "center" },
    ]);
  }, [literatures]);
  return (
    <Grid container spacing={2} pt={6} pb={3}>
      {literatures.length < 0 ? (
        <div className="d-flex justify-content-center align-item-center h-100">
          <span className="spinner-grow" role="status"></span>
        </div>
      ) : (
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="contain"
              bgColor={sidenavColor}
              borderRadius="lg"
              coloredShadow={sidenavColor}
              className="d-flex justify-content-between align-items-center pt-1 pb-1"
            >
              <MDTypography variant="h4" color="white">
                literature
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {literatures.length > 0 ? (
                <DataTable
                  table={{
                    rows: rows,
                    columns: columns,
                  }}
                  isSorted={false}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                  entriesPerPage={{ defaultValue: 20, entries: [10, 20, 30] }}
                  AddButton={
                    <MDButton
                      color={sidenavColor}
                      onClick={() => {
                        navigate("/literature/add");
                      }}
                    >
                      <MDTypography fontSize={15} fontWeight="bold" color="inherit">
                        Add New Literature
                      </MDTypography>
                    </MDButton>
                  }
                  // pagination={{ variant: "contained", color: sidenavColor }}
                />
              ) : (
                <div className="d-flex justify-content-center align-item-center h-100">
                  <span className="spinner-grow" role="status"></span>
                </div>
              )}
            </MDBox>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Literature;
