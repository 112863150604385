import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  notificationBars: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationAction: (state, action) => {
      state.notificationBars.push({ text: action.payload.text, color: action.payload.color });
    },
    notificationClear: (state, action) => {
      state.notificationBars.pop(action.payload);
    },
  },
});
export const { notificationAction, notificationClear } = notificationSlice.actions;

export const notificationHandler = (text, color, dispatch) => {
  dispatch(
    notificationAction({
      text,
      color,
    })
  );
  setTimeout(() => {
    dispatch(notificationClear(0));
  }, 5000);
};

export default notificationSlice.reducer;
