import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import MDButton from "components/MDButton";
import { UserContext } from "App";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetAllEventAPI } from "APIs/EventAPIs";
import { DeleteEventAPI } from "APIs/EventAPIs";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import DataTable from "templates/Tables/DataTable";
import MDBadge from "components/MDBadge";
import Swal from "sweetalert2";
import { popUp } from "components/Custom/AreYouSurePopUp";
const Index = () => {
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const getEventsFunc = async (access_token, dispatch) => {
    const response = await GetAllEventAPI(access_token, dispatch);
    if (response !== null) {
      setEvents([...response]);
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getEventsFunc(userData.access_token, dispatch);
    }
  }, [userData]);

  const handleDelete = async (id) => {
    const response = await DeleteEventAPI(
      {
        events_id: id,
      },
      userData.access_token,
      dispatch
    );
    if (response !== null) {
      getEventsFunc(userData.access_token, dispatch);
    }
  };
  useEffect(() => {
    setRows([
      ...events?.map((item, key) => {
        return {
          sr: `${key + 1}`,
          pic: <MDAvatar className="bg-dark" src={item.media} alt="Avatar" size="md" />,
          title: item.event_title,
          start: item?.event_start_date?.slice(0, 10).split("-").reverse().join("-"),
          end: item?.event_end_date?.slice(0, 10).split("-").reverse().join("-"),
          status: (
            <MDBadge
              badgeContent={item.status == 0 ? "INACTIVE" : "ACTIVE"}
              size="sm"
              color={item.status == 0 ? "secondary" : "success"}
              variant="contained"
              container
            />
          ),
          action: (
            <>
              <MDButton
                className="me-2"
                color={sidenavColor}
                onClick={() => {
                  navigate(`edit/${item.events_id}`);
                }}
              >
                Edit
              </MDButton>
              <MDButton
                className="me-2"
                color={sidenavColor}
                onClick={() => {
                  navigate(`view/${item.events_id}`);
                }}
              >
                view
              </MDButton>
              <MDButton
                color="error"
                onClick={async () => {
                  await popUp(handleDelete, item.events_id);
                }}
              >
                Delete
              </MDButton>
            </>
          ),
        };
      }),
    ]);
    setColumns([
      { Header: "sr no", accessor: "sr", align: "center", width: "10%" },
      { Header: "pic", accessor: "pic", align: "center", width: "10%" },
      { Header: "title", accessor: "title", width: "20%" },
      { Header: "start date", accessor: "start", width: "15%" },
      { Header: "end date", accessor: "end", width: "15%" },
      { Header: "status", accessor: "status", align: "center", width: "10%" },
      { Header: "action", accessor: "action", align: "center", width: "20%" },
    ]);
  }, [events]);
  return (
    <Grid container spacing={2} pt={6} pb={3}>
      {events.length < 0 ? (
        <div className="d-flex justify-content-center align-item-center h-100">
          <span className="spinner-grow" role="status"></span>
        </div>
      ) : (
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              px={2}
              variant="contain"
              bgColor={sidenavColor}
              borderRadius="lg"
              coloredShadow={sidenavColor}
              className="d-flex justify-content-between align-items-center pt-1 pb-1"
            >
              <MDTypography variant="h4" color="white">
                Events
              </MDTypography>
            </MDBox>
            <MDBox pt={3} width="100%">
              {events.length > 0 ? (
                <DataTable
                  wdith="100%"
                  table={{
                    rows: rows,
                    columns: columns,
                  }}
                  isSorted={false}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                  entriesPerPage={{ defaultValue: 20, entries: [10, 20, 30] }}
                  AddButton={
                    <MDButton
                      color={sidenavColor}
                      onClick={() => {
                        navigate("/event/add");
                      }}
                    >
                      <MDTypography fontSize={15} fontWeight="bold" color="inherit">
                        Add New Events
                      </MDTypography>
                    </MDButton>
                  }
                  // pagination={{ variant: "contained", color: sidenavColor }}
                />
              ) : (
                <div className="d-flex justify-content-center align-item-center h-100">
                  <span className="spinner-grow" role="status"></span>
                </div>
              )}
            </MDBox>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;
