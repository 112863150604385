import React, { useContext, useState } from "react";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddNewAlbum } from "APIs/AlbumAPIs";
import { uploadAlbumMedia } from "APIs/AlbumAPIs";
import { UploadLiterature } from "APIs/LiteratureAPI";
const AddLiterature = () => {
  const [controller] = useMaterialUIController();
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [literature, setLiterature] = useState();
  const [file, setFile] = useState([]);
  const handleLiteratureDataChange = (e) => {
    setLiterature(e.target.value);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("literature_title", literature);
    formData.append("files", file);
    const LiteratureResponse = await UploadLiterature(formData, userData.access_token, dispatch);
    if (LiteratureResponse !== null) {
      navigate("/literature");
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={9}>
        <Card>
          {" "}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} px={3} pt={2}>
              <Grid item xs={12}>
                <MDTypography variant="h2">Add Literature</MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                Title
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  value={literature}
                  type="text"
                  fullWidth={true}
                  onChange={handleLiteratureDataChange}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                Media
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
                style={{ position: "relative" }}
              >
                {/* <MDButton
                  color={sidenavColor}
                  className="me-3"
                  style={{ zIndex: 0, width: "150px" }}
                >
                  Select File{" "}
                </MDButton> */}
                <input
                  required
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  //   style={{ opacity: 0, position: "absolute", zIndex: 1, width: "150px" }}
                />
                {/* <MDTypography variant="body2">
                  {file.length > 0 ? `Selected files - ${file.length}` : ""}
                </MDTypography> */}
              </Grid>
              <Grid item xs={12} align="end" my={3}>
                <MDButton color="success" type="submit">
                  Save Literature
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddLiterature;
