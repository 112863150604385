import axios from "axios";
import { URL } from "./MainAPILink";
import { notificationHandler } from "reducers/notificationSlice";
import CatchMessage from "./catchMessage";
const AddNewAlbum = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "media/album", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("New Album Added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const uploadAlbumMedia = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "media", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    // notificationHandler("Album Images added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

const GetAlbumDetails = async (data, access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "media?media_album_id=" + data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const GetAllAlbums = async (access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "media/album", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const DeleteAlbum = async (data, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "media/album?media_album_id=" + data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("BLog Deleted !", "success", dispatch);
    return response.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const EditAlbumAPI = async (data, access_token, dispatch) => {
  try {
    const response = await axios.put(URL + "media/album", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Album Edited", "success", dispatch);
    return response.data.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

export { AddNewAlbum, uploadAlbumMedia, GetAlbumDetails, GetAllAlbums, DeleteAlbum, EditAlbumAPI };
