import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import { UserContext } from "App";
import { GetEventDetailsAPI } from "APIs/EventAPIs";
import MDTypography from "components/MDTypography";
import DataTable from "templates/Tables/DataTable";
import ReactPlayer from "react-player";
const ViewEvent = () => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [eventData, setEventData] = useState();
  const [mediaList, setMediaList] = useState();
  const getEventFunc = async (id, access_token, dispatch) => {
    const response = await GetEventDetailsAPI(id, access_token, dispatch);
    if (response !== null) {
      setEventData({ ...response });
    }
  };
  const mediaListSeparation = () => {
    var temp = { photo: [], video: [] };
    for (var i of eventData?.media_list) {
      if (i.media_type == 1) {
        temp["photo"].push(i);
      } else {
        temp["video"].push(i);
      }
    }
    setMediaList({ ...temp });
  };
  useEffect(() => {
    if (eventData?.media_list !== undefined) {
      mediaListSeparation();
    }
  }, [eventData]);
  useEffect(() => {
    if (userData.access_token !== null) {
      getEventFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          {eventData !== undefined ? (
            <Grid container spacing={2} py={2} px={2}>
              <Grid item lg={3} md={5} xs={12} alignContent="center">
                <img
                  style={{ width: "100%", borderRadius: "10px" }}
                  src={
                    mediaList?.photo !== undefined || mediaList?.photo.length > 0
                      ? mediaList?.photo[0]?.media
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={9} md={7} xs={12}>
                <MDTypography variant="h1">{eventData?.event_title}</MDTypography>
                <MDTypography variant="body2">
                  {eventData?.event_start_date.slice(0, 10).split("-").reverse().join("-")} ---{" "}
                  {eventData?.event_end_date.slice(0, 10).split("-").reverse().join("-")}
                </MDTypography>
                <MDTypography
                  variant="body1"
                  className="ms-5 mt-3"
                  dangerouslySetInnerHTML={{ __html: eventData?.event_description }}
                ></MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography fontSize={30} variant="inherit" className="ms-3">
                  {" "}
                  Schedule
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>
              <Grid item xs={12}>
                <DataTable
                  table={{
                    rows: [
                      ...eventData?.schedule_list?.map((item, key) => {
                        return {
                          title: (
                            <MDTypography variant="body2">
                              {item?.event_schedule_title}
                            </MDTypography>
                          ),
                          date: (
                            <MDTypography variant="body2">
                              {item?.event_schedule_date.slice(0, 10)}
                            </MDTypography>
                          ),
                          time: (
                            <MDTypography variant="body2">
                              {item?.event_schedule_time.slice(11, 16)}
                            </MDTypography>
                          ),
                        };
                      }),
                    ],
                    columns: [
                      { Header: "title", accessor: "title", align: "center" },
                      { Header: "date", accessor: "date", align: "center" },
                      { Header: "time", accessor: "time", align: "center" },
                    ],
                  }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                  // canSearch={true}
                  entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
                  // pagination={{ variant: "contained", color: sidenavColor }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography
                  fontSize={25}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  Photos
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
                <div style={{ columns: "2" }}>
                  {mediaList?.photo?.map((item, key) => {
                    return (
                      <div style={{ display: "flex", paddingTop: "15px" }} key={key}>
                        <a target="blank" href={item.media}>
                          {" "}
                          <img style={{ width: "100%", borderRadius: "10px" }} src={item.media} />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography
                  fontSize={25}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  Videos
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
                {mediaList?.video?.map((item, key) => {
                  return (
                    <div className="d-flex justify-content-center p-3" key={key}>
                      {/* <img style={{ width: "100%", borderRadius: "10px" }} src={item.media} /> */}
                      {ReactPlayer.canPlay(item.media) ? (
                        <ReactPlayer
                          controls={true}
                          url={item.media}
                          style={{ width: "100%", borderRadius: "10px" }}
                          alt={item.media}
                        />
                      ) : (
                        <a href={item.media}>Click here for video</a>
                      )}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          ) : (
            <div className="d-flex justify-content-center align-item-center h-100">
              <span className="spinner-grow" role="status"></span>
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewEvent;
