import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserContext } from "App";
import Card from "@mui/material/Card";
import { GetDevoteeDataAPI } from "APIs/DevoteeAPIs";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import { GetDevoteeAddressAPI } from "APIs/DevoteeAPIs";
import { Loader } from "components/Custom/Loader";
import MDInput from "components/MDInput";
import { notify } from "App";
const ViewDevotee = () => {
  const dispatch = useDispatch();
  const [devoteeData, setDevoteeData] = useState({});
  const [devoteeAddress, setDevoteeAddress] = useState([]);
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;
  const { devotee_id } = useParams();
  const GetDevoteeDataAPIFunc = async (devotee_id, access_token) => {
    const response = await GetDevoteeDataAPI(devotee_id, access_token);
    if (response.is_success) {
      setDevoteeData({ ...response.data, gender: response.data.gender == 0 ? "Male" : "Female" });
    } else {
      notify(response.message);
    }
  };
  const GetDevoteeAddressFunc = async (devotee_id, access_token) => {
    const response = await GetDevoteeAddressAPI(devotee_id, access_token);
    if (response.is_success) {
      setDevoteeAddress(response.data.list);
    } else {
      notify(response.message);
    }
  };

  useEffect(() => {
    if (userData.access_token) {
      GetDevoteeDataAPIFunc(devotee_id, userData.access_token);
    }
  }, [userData]);
  useEffect(() => {
    if (userData?.access_token && devoteeData?.devotee_id) {
      GetDevoteeAddressFunc(devoteeData.devotee_id, userData.access_token);
    }
  }, [devoteeData, userData]);
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={9}>
        {devoteeData ? (
          <Card>
            <MDBox color={darkMode ? "white" : "Dark"} borderRadius="lg" shadow="xxl" p={2}>
              <Grid container spacing={2} className="mb-3 d-flex justify-content-center">
                <Grid item xxl={3} md={4} sm={6} className="ps-4">
                  <img
                    onError="https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/user.png"
                    style={{
                      borderRadius: "100%",
                      width: "170px",
                      height: "170px",
                      backgroundColor: "white",
                    }}
                    src={
                      devoteeData.picture &&
                      devoteeData.picture[devoteeData.picture.length - 1] !== "/"
                        ? devoteeData.picture
                        : "https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/user.png"
                    }
                    alt="Avatar"
                  />
                </Grid>
                <Grid item xxl={9} md={8} sm={6} className="d-flex align-items-center">
                  <div>
                    <MDTypography
                      fontSize={30}
                      fontWeight={"regular"}
                      variant="inherit"
                      className="d-flex justify-content-center"
                    >{`${devoteeData?.first_name} ${devoteeData?.last_name}`}</MDTypography>
                    <MDTypography
                      fontSize={17}
                      fontWeight={"regular"}
                      variant="inherit"
                      className="d-flex justify-content-center"
                    >
                      {devoteeData.email ? (
                        <a href={`mailto:${devoteeData.email}`}>{devoteeData.email}</a>
                      ) : (
                        ""
                      )}
                    </MDTypography>{" "}
                    <MDTypography
                      fontSize={17}
                      fontWeight={"regular"}
                      variant="inherit"
                      className="d-flex justify-content-center"
                    >
                      {devoteeData?.comments}
                    </MDTypography>{" "}
                  </div>
                </Grid>
              </Grid>
              <MDTypography fontSize={30} variant="inherit" className="ms-3">
                Details
              </MDTypography>
              <hr
                style={{
                  backgroundColor: "#7b809a",
                }}
              ></hr>
              {devoteeData ? (
                <Grid container spacing={2} className="mt-3">
                  {Object.keys(devoteeData)?.map((items, key) => {
                    return (
                      <>
                        {[
                          "first_name",
                          "last_name",
                          "anniversary_date",
                          "birth_date",
                          "email",
                          "gender",
                          "middle_name",
                          "phone",
                          "phone_code",
                        ].includes(items) ? (
                          <>
                            <Grid item xs={6} md={3} className="d-flex justify-content-between">
                              <MDTypography
                                textTransform="capitalize"
                                className="ps-3"
                                fontSize={17}
                              >
                                {items.split("_").join(" ")}
                              </MDTypography>
                            </Grid>
                            <Grid item xs={6} md={9} key={key}>
                              <MDInput
                                style={{ width: "70%" }}
                                className=""
                                fontSize={15}
                                disabled
                                align="left"
                                textTransform="capitalize"
                                size="lg"
                                fullWidth={true}
                                value={devoteeData[items] ? devoteeData[items] : ""}
                              />
                            </Grid>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </Grid>
              ) : (
                <Loader />
              )}
              {devoteeAddress?.length > 0 ? (
                <Grid item xs={12}>
                  <MDTypography fontSize={30} variant="inherit" className="ms-3" ps={2} mt={4}>
                    Addresses
                  </MDTypography>
                  <hr
                    style={{
                      backgroundColor: "#7b809a",
                    }}
                  ></hr>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12}>
                {devoteeAddress?.map((addresses, key) => {
                  return (
                    <Grid container spacing={2} className="mt-3" key={key}>
                      <Grid item xs={12} style={{ display: "flex" }}>
                        <MDTypography
                          style={{ borderBottom: "outset 1px rgba(123, 128, 154,0.2)" }}
                        >
                          Address {key + 1}
                        </MDTypography>
                      </Grid>

                      {Object.keys(addresses)?.map((items) => {
                        return (
                          <>
                            {[
                              "address_line_1",
                              "address_line_2",
                              "area",
                              "city",
                              "country",
                              "landmark",
                              "phone",
                              "pincode",
                              "state",
                              "title",
                            ].includes(items) ? (
                              <>
                                <Grid item xs={6} lg={2} className="d-flex justify-content-between">
                                  <MDTypography
                                    textTransform="capitalize"
                                    className="ps-3"
                                    fontSize={17}
                                  >
                                    {items.split("_").join(" ")}
                                  </MDTypography>
                                </Grid>
                                <Grid item xs={6} lg={4}>
                                  <MDInput
                                    disabled
                                    align="left"
                                    textTransform="capitalize"
                                    className=""
                                    fontSize={15}
                                    size="lg"
                                    fullWidth={true}
                                    value={addresses[items]}
                                  />
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                      <hr
                        style={{
                          backgroundColor: "#7b809a",
                        }}
                      ></hr>
                    </Grid>
                  );
                })}
              </Grid>
            </MDBox>
          </Card>
        ) : (
          <Loader />
        )}
      </Grid>
    </Grid>
  );
};

export default ViewDevotee;
