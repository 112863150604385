import React, { useContext, useState, useEffect } from "react";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { EditBlogApi, GetBlogDetails, uploadImageBlog } from "APIs/BlogAPIs";
import Swal from "sweetalert2";
const EditBlog = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState({});
  const [file, setFile] = useState([]);
  const handleBlogDataChange = (e) => {
    setBlogData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleFileChange = (e) => {
    setFile(e.target.files);
  };
  const { id } = useParams();
  const getBlogDetailsFunc = async (id, access_token, dispatch) => {
    const response = await GetBlogDetails(id, access_token, dispatch);
    if (response !== null) {
      setBlogData((prev) => {
        return {
          ...prev,
          title: response.blog_title,
          description: response.blog_description,
          blog_id: response.blog_id.toString(),
        };
      });
    }
  };

  useEffect(() => {
    if (userData.access_token !== undefined) {
      getBlogDetailsFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, edit it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(blogData);
        const editBlog = await EditBlogApi(blogData, userData.access_token, dispatch);
        if (editBlog !== null) {
          if (file.length > 0) {
            let formData = new FormData();
            formData.append("blog_id", blogData?.blog_id);
            for (var i in file) {
              formData.append("files", file[i]);
            }
            const uploadMedia = await uploadImageBlog(formData, userData.access_token, dispatch);
            if (uploadMedia !== null) {
              navigate("/blog");
            }
          } else {
            navigate("/blog");
          }
        }
      }
    });
  };

  return (
    <Grid container>
      <Grid item lg={9}>
        <Card>
          {" "}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} px={3} pt={2}>
              <Grid item xs={12}>
                <MDTypography variant="h2">Edit Blog</MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>

              <Grid item xs={6} lg={3}>
                Title
              </Grid>
              <Grid item xs={6} lg={9} className="d-flex justify-content-start align-items-center">
                <MDInput
                  name="title"
                  type="text"
                  value={blogData?.title}
                  fullWidth={true}
                  onChange={handleBlogDataChange}
                />
              </Grid>
              <Grid item xs={6} lg={3} className="d-flex justify-content-start align-items-center">
                Description
              </Grid>
              <Grid item xs={6} lg={9} className="d-flex justify-content-start align-items-center">
                <div style={{ width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={blogData?.description}
                    onChange={(event, editor) => {
                      // console.log(blogData?.description);
                      handleBlogDataChange({
                        target: { name: "description", value: `${editor.getData()}` },
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6} lg={3}>
                Upload new media
              </Grid>
              <Grid
                item
                xs={6}
                lg={9}
                className="d-flex justify-content-start align-items-center"
                style={{ position: "relative" }}
              >
                {/* <MDButton
                  color={sidenavColor}
                  className="me-3"
                  style={{ zIndex: 0, width: "150px" }}
                >
                  Select File{" "}
                </MDButton> */}
                <input
                  type="file"
                  name="image"
                  multiple
                  accept=".png,.jpg,.jpeg,.gif,.webp,.tiff,.psd,
                  .bmp,.ico/.icon/x-icon"
                  onChange={handleFileChange}
                  // style={{ opacity: 0, position: "absolute", zIndex: 1, width: "150px" }}
                />
                {/* <MDTypography>Number of files choosen : {file.length}</MDTypography> */}
                {/* <input type="file" name="image" multiple /> */}
                {/* // <MDInput type="file" fullWidth={true} multiple="multiple" /> */}
              </Grid>
              <Grid item xs={12} align="end" my={3}>
                <MDButton color="success" type="submit">
                  Save Blog
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditBlog;
