import React, { useContext, useEffect, useState } from "react";
import { Grid, Card, Select, MenuItem } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useParams, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { AddDevoteeAPI } from "APIs/DevoteeAPIs";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { notify } from "App";
import { SelectDropDown } from "components/Custom/SelectDropDown";
import { GetCountryCodeList } from "APIs/CommonAPI";
const AddDevotee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const [devoteeData, setDevoteeData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    country_code: "",
    phone: "",
    gender: "",
    birth_date: "",
    anniversary_date: "",
    comments: "",
  });
  const handleDataChange = (field, value) => {
    setDevoteeData({ ...devoteeData, [field]: value });
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    var temp = {
      ...devoteeData,
      country_code: Number(currentSelected.value),
    };
    const response = await AddDevoteeAPI(temp, userData.access_token, dispatch);
    if (response.is_success) {
      navigate(`/devotee/new_address/${response.data.devotee_id}`);
    }
    notify(response.message);
  };
  const [currentSelected, setCurrentSelected] = useState({
    value: 112,
    label: "INDIA  :  +91",
  });

  const [countryList, setCountryList] = useState([]);

  const getCountryCode = async () => {
    const response = await GetCountryCodeList(userData.access_token);
    setCountryList(
      response.map((value) => {
        return {
          value: value.country_currency_id,
          label: `${value.country_name}  :  ${value.phone_code}`,
        };
      })
    );
  };

  // const filterCountryCode = async (input) => {
  //   return countryList
  //     // ?.filter(
  //     //   (value) =>
  //     //     value.country_name.includes(input.toLowerCase()) ||
  //     //     value.phone_code.includes(input.toLowerCase())
  //     // )
  //     ?.map((value) => {
  //       return {
  //         value: value.country_currency_id,
  //         label: `${value.country_name}  :  ${value.phone_code}`,
  //       };
  //     });
  // };

  useEffect(() => {
    getCountryCode();
  }, [userData]);

  return (
    <Grid container>
      <Grid item lg={9}>
        <Card>
          <MDTypography variant="h2" className="p-3 pb-5">
            Add Devotee
          </MDTypography>
          <form onSubmit={handleOnSubmit}>
            <Grid container spacing={2}>
              {Object.keys(devoteeData)?.map((items) => {
                return (
                  <>
                    {[
                      "first_name",
                      "middle_name",
                      "last_name",
                      "email",
                      "phone",
                      "gender",
                      "birth_date",
                      "anniversary_date",
                      "comments",
                    ].includes(items) ? (
                      <>
                        <Grid item xs={4} className="d-flex justify-content-between">
                          <MDTypography textTransform="capitalize" className="ps-3" fontSize={17}>
                            {items.split("_").join(" ")}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={8}>
                          {items == "gender" ? (
                            <div style={{ width: "70%", height: "45px", position: "relative" }}>
                              <Select
                                style={{ width: "100%", height: "100%" }}
                                value={devoteeData[items]}
                                onChange={(e) => {
                                  handleDataChange(items, e.target.value);
                                }}
                                required
                              >
                                <MenuItem value={0}>Male</MenuItem>
                                <MenuItem value={1}>Female</MenuItem>
                              </Select>
                              <ArrowDropDownIcon
                                fontSize="large"
                                style={{
                                  position: "absolute",
                                  top: 5,
                                  right: 5,
                                  pointerEvents: "none",
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ width: "70%" }}>
                              <MDInput
                                required={
                                  items == "birth_date" ||
                                  items == "email" ||
                                  items == "middle_name" ||
                                  items == "comments" ||
                                  items == "anniversary_date"
                                    ? false
                                    : true
                                }
                                onChange={(e) => {
                                  handleDataChange(
                                    items,
                                    e.target.value.split("-").reverse().join("-")
                                  );
                                }}
                                type={
                                  items == "birth_date" || items == "anniversary_date"
                                    ? "date"
                                    : items == "phone"
                                    ? "number"
                                    : "text"
                                }
                                align="left"
                                textTransform="capitalize"
                                className=""
                                fontSize={15}
                                size="lg"
                                fullWidth={true}
                                value={
                                  items == "birth_date" || items == "anniversary_date"
                                    ? `${devoteeData[items]}`.split("-").reverse().join("-")
                                    : devoteeData[items]
                                }
                              />
                            </div>
                          )}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              <Grid item xs={4} className="d-flex justify-content-between">
                <MDTypography textTransform="capitalize" className="ps-3" fontSize={17}>
                  Country Code{" "}
                </MDTypography>
              </Grid>
              <Grid item xs={8}>
                {countryList.length > 0 ? (
                  <div style={{ width: "70%" }}>
                    <SelectDropDown
                      defaultOptions={currentSelected}
                      currentSelected={currentSelected}
                      setCurrentSelected={(e) => {
                        setCurrentSelected(e);
                      }}
                      data={countryList}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <MDBox width="80%" align="end">
                  <MDButton type="submit" className="mb-3" color={"success"}>
                    Save Devotee
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddDevotee;
