import { URL } from "./MainAPILink";
import SetCookies from "Cookies/setCookies";
import RemoveCookies from "Cookies/removeCookies";
import axios from "axios";
const Login = async ({ phone, password, dispatch }) => {
  try {
    const response = await axios.post(URL + "auth/admin/login", {
      phone: phone,
      password: password,
    });
    if (response.data) {
      await SetCookies("userData", JSON.stringify(response.data.data.access_token));
    }
    return response.data.data;
  } catch (err) {
    // CatchMessage(err, dispatch);
    console.log(err);
    return null;
  }
};
const Logout = () => {};
const AutoLogin = async (args) => {
  try {
    const response = await axios.post(URL + "auth/auto_login", {
      authorization: `${args}`,
      device_token: "WebApp",
    });

    if (response.data) {
      await RemoveCookies("userData");
      await SetCookies("userData", JSON.stringify(response.data.data.access_token));
    }

    return response.data.data;
  } catch (err) {
    console.log(err);
    if (err.response.data.status == 422) {
      await RemoveCookies("userData");
      localStorage.removeItem("userData");
    }
    return null;
  }
};
export { Login, AutoLogin, Logout };
