import { UserContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useMaterialUIController } from "context";
import EventSchedules from "./schedules";
import { UploadEventMediaAPI } from "APIs/EventAPIs";
import MDBox from "components/MDBox";
import { EditEventAPI } from "APIs/EventAPIs";
import { GetEventDetailsAPI } from "APIs/EventAPIs";
import { popUp } from "components/Custom/AreYouSurePopUp";

const EditEvent = () => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const { id } = useParams();
  const [eventData, setEventData] = useState({});
  const [scheduleData, setScheduleData] = useState([
    {
      event_schedule_title: "",
      event_schedule_date: "",
      event_schedule_time: "",
    },
  ]);
  const [files, setFiles] = useState([]);
  const getEventFunc = async (id, access_token, dispatch) => {
    const response = await GetEventDetailsAPI(id, access_token, dispatch);
    if (response !== null) {
      setEventData({
        event_id: response.events_id,
        event_title: response.event_title,
        event_description: response.event_description,
        event_start_date: response.event_start_date,
        event_end_date: response.event_end_date,
      });
      setScheduleData([...response.schedule_list]);
    }
  };
  const handleEventDataChange = (field) => {
    if (field.target.name == "event_start_date" || field.target.name == "event_end_date") {
      setEventData((prev) => {
        return {
          ...prev,
          [field.target.name]: field.target.value,
        };
      });
      console.log(eventData);
    } else {
      setEventData((prev) => {
        return { ...prev, [field.target.name]: field.target.value };
      });
    }
  };
  const handleScheduleDataChange = (field, key) => {
    const tempData = scheduleData;
    if (field.target.name == "event_schedule_date") {
      tempData[key] = {
        ...tempData[key],
        [field.target.name]: `${field.target.value} 00:00:00`,
      };
    } else if (field.target.name == "event_schedule_time") {
      tempData[key] = {
        ...tempData[key],
        [field.target.name]: `${scheduleData[key].event_schedule_date.slice(0, 10)} ${
          field.target.value
        }:00`,
      };
    } else tempData[key] = { ...tempData[key], [field.target.name]: field.target.value };
    setScheduleData([...tempData]);
  };
  const addSchedule = () => {
    setScheduleData([
      ...scheduleData,
      {
        event_schedule_title: "",
        event_schedule_date: "",
        event_schedule_time: "",
      },
    ]);
  };
  const deleteSchedule = (key) => {
    let temp = scheduleData;
    temp = temp.filter((item, index) => index !== key);
    setScheduleData([...temp]);
  };
  const handleMediaUpload = async (event_id) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append("event_id", event_id);
      for (var i in files) {
        formData.append("files", files[i]);
      }
      const uploadResponse = await UploadEventMediaAPI(formData, userData.access_token, dispatch);
      if (uploadResponse !== null) {
        navigate("/event/view/" + id);
      }
    } else {
      navigate("/event/view/" + id);
    }
  };
  const handleOnFileChange = (e) => {
    setFiles(e.target.files);
  };
  const handleOnSubmit = async () => {
    var sendData = eventData;
    sendData.schedule = scheduleData;
    console.log(sendData);
    const editResponse = await EditEventAPI(sendData, userData.access_token, dispatch);
    if (editResponse !== null) {
      await handleMediaUpload(editResponse.events_id);
    }
  };
  useEffect(() => {
    if (userData.access_token !== null) {
      getEventFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={9}>
        <Card>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await popUp(handleOnSubmit);
            }}
          >
            <Grid container spacing={2} px={3} pt={2}>
              <Grid item xs={12}>
                <MDTypography variant="h2">Edit Event</MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <MDTypography>Title</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  name="event_title"
                  type="text"
                  value={eventData?.event_title}
                  fullWidth={true}
                  onChange={handleEventDataChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                className="d-flex justify-content-start align-items-center"
              >
                <MDTypography>Description</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <div style={{ width: "100%" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={
                      eventData.event_description == undefined
                        ? "enter text"
                        : eventData?.event_description
                    }
                    onChange={(event, editor) => {
                      handleEventDataChange({
                        target: { name: "event_description", value: editor.getData() },
                      });
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                className="d-flex justify-content-start align-items-center"
              >
                <MDTypography> Start Date</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  name="event_start_date"
                  type="date"
                  value={`${eventData?.event_start_date?.slice(0, 10)}`}
                  fullWidth={true}
                  onChange={handleEventDataChange}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                className="d-flex justify-content-start align-items-center"
              >
                <MDTypography> End Date</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  name="event_end_date"
                  type="date"
                  fullWidth={true}
                  value={`${eventData?.event_end_date?.slice(0, 10)}`}
                  onChange={handleEventDataChange}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography fontSize={30} variant="inherit" className="ms-3" p={1}>
                  Schedules
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <EventSchedules
                  data={scheduleData}
                  handleDataChange={handleScheduleDataChange}
                  deleteSchedule={deleteSchedule}
                />
                <Grid container py={3}>
                  <Grid item xs={12} align="end">
                    <MDButton color={sidenavColor} onClick={addSchedule}>
                      Add Event Schedule
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <MDTypography>Upload Media</MDTypography>
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
                style={{ position: "relative" }}
              >
                <MDBox>
                  <input type="file" name="image" multiple onChange={handleOnFileChange} />
                </MDBox>

                <MDTypography variant="body2">
                  {files.length > 0 ? `Selected files - ${files.length}` : ""}
                </MDTypography>
              </Grid>
              <Grid item xs={12} align="end" my={3} mx={3}>
                <MDButton type="submit" color="success">
                  Save Event
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditEvent;
