import axios from "axios";
import { notificationHandler } from "reducers/notificationSlice";
import { URL } from "./MainAPILink";
import CatchMessage from "./catchMessage";

const GetAllDevoteeDataAPI = async (access_token, page_no) => {
  try {
    const response = await axios.get(URL + "devotee?page_no=" + page_no, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);

    return response.data;
  }
};

const AddDevoteeAPI = async (data, access_token) => {
  try {
    const response = await axios.post(URL + "devotee", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);

    return err.response.data;
  }
};

const AddAddressAPI = async (data, access_token) => {
  try {
    const response = await axios.post(URL + "devotee/address", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};
const GetDevoteeDataAPI = async (devotee_id, access_token) => {
  try {
    const response = await axios.get(URL + "devotee?devotee_id=" + devotee_id, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);

    return response.data;
  }
};
const GetDevoteeAddressAPI = async (devotee_id, access_token) => {
  try {
    const ReqUrl = URL + "devotee/address?devotee_id=" + devotee_id;
    const response = await axios.get(ReqUrl, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};

const EditDevoteDataAPI = async (data, access_token) => {
  try {
    const response = await axios.put(URL + "devotee", data, {
      headers: { Authorization: `Bearer ${access_token}` },
      "Content-Type": "application/json",
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};
const EditDevoteeAddressAPI = async (data, access_token) => {
  try {
    const response = await axios.put(URL + "devotee/address", data, {
      headers: { Authorization: `Bearer ${access_token}` },
      "Content-Type": "application/json",
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};

const UploadDevoteeImageAPI = async (data, access_token) => {
  try {
    const response = await axios.post(URL + "devotee/picture", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};

const DeleteAddressAPI = async (data, access_token) => {
  try {
    const response = await axios.delete(URL + "devotee/address", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        devotee_address_id: `${data}`,
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};
const FindDevoteeAPI = async (text, page_no, access_token) => {
  try {
    const response = await axios.get(URL + `devotee/find?text=${text}&page_no=${page_no}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    CatchMessage(err);
    return err.response.data;
  }
};

export {
  FindDevoteeAPI,
  AddDevoteeAPI,
  DeleteAddressAPI,
  AddAddressAPI,
  GetDevoteeAddressAPI,
  GetDevoteeDataAPI,
  UploadDevoteeImageAPI,
  EditDevoteDataAPI,
  GetAllDevoteeDataAPI,
  EditDevoteeAddressAPI,
};
