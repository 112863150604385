import React, { useContext, useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { GetAlbumDetails } from "APIs/AlbumAPIs";
import ReactPlayer from "react-player";
const ViewAlbum = () => {
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [AlbumDetails, setAlbumDetails] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const getAlbumDetailsFunc = async (id, access_token, dispatch) => {
    const response = await GetAlbumDetails(id, access_token, dispatch);
    if (response !== null) {
      setAlbumDetails({ ...response });
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getAlbumDetailsFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className="pb-5">
          <Grid container spacing={2} px={3}>
            <Grid item xs={12}>
              <MDTypography
                variant="h1"
                my={3}
                width="100%"
                className="d-flex justify-content-center"
              >
                {AlbumDetails?.media_album_title}
              </MDTypography>
            </Grid>
            <Grid item xs={12} className="d-flex justify-content-center">
              <div
                style={{
                  columns: window.innerWidth < 555 ? "1" : window.innerWidth < 1300 ? "2" : "3",
                  width: "90%",
                }}
              >
                {AlbumDetails?.list?.map((item, key) => {
                  return (
                    <div
                      style={{ display: "flex", paddingTop: "15px", justifyContent: "center" }}
                      key={key}
                    >
                      {item?.media_type == 1 ? (
                        <a target="blank" href={item.media_location}>
                          {" "}
                          <img
                            style={{ width: "100%", borderRadius: "10px" }}
                            src={item.media_location}
                          />
                        </a>
                      ) : (
                        <>
                          {ReactPlayer.canPlay(item.media_location) ? (
                            <ReactPlayer
                              controls={true}
                              url={item.media_location}
                              style={{ width: "100%", borderRadius: "10px" }}
                              alt={item.media_location}
                            />
                          ) : (
                            <a href={item.media_location}>Click here for video</a>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewAlbum;
