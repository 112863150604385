import React, { useContext, useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import { UserContext } from "App";
import MDTypography from "components/MDTypography";
import DataTable from "templates/Tables/DataTable";
import ReactPlayer from "react-player";
import { GetMediaByAlbums } from "APIs/AudioAPIs";
const ViewAudio = () => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [audioData, setAudioData] = useState([]);
  const getAudiosFunc = async (id, access_token, dispatch) => {
    const response = await GetMediaByAlbums(id, access_token, dispatch);
    if (response !== null) {
      setAudioData([...response]);
    }
  };

  useEffect(() => {
    if (userData.access_token !== null) {
      getAudiosFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          {audioData.length > 0 ? (
            <Grid container spacing={2} py={2} px={4}>
              <Grid item xs={12}>
                <MDTypography variant="h1">{audioData[0]?.audio_album_title}</MDTypography>
              </Grid>

              <Grid item xs={12}>
                <DataTable
                  table={{
                    rows: [
                      ...audioData?.map((item, key) => {
                        return {
                          title: <MDTypography variant="body2">{item?.audio_title}</MDTypography>,
                          audio: (
                            <audio controls>
                              <source src={item?.audio_location} />
                            </audio>
                          ),
                        };
                      }),
                    ],
                    columns: [
                      { Header: "title", accessor: "title", align: "center" },
                      { Header: "audio", accessor: "audio", align: "center" },
                    ],
                  }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                  // canSearch={true}
                  entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
                  // pagination={{ variant: "contained", color: sidenavColor }}
                />
              </Grid>
            </Grid>
          ) : (
            <div className="d-flex justify-content-center align-item-center h-100">
              <span className="spinner-grow" role="status"></span>
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewAudio;
