import axios from "axios";
import { URL } from "./MainAPILink";
import { notificationHandler } from "reducers/notificationSlice";
import CatchMessage from "./catchMessage";

const AddNewEventAPI = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "events", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    notificationHandler("New Event is Added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const UploadEventMediaAPI = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "events/upload", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    // notificationHandler("Media uploaded", "success", dispatch);
    return response.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const GetEventDetailsAPI = async (events_id, access_token, dispatch) => {
  try {
    const response = await axios.post(
      URL + "events/get_event",
      { events_id: events_id },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const GetAllEventAPI = async (access_token, dispatch) => {
  try {
    const response = await axios.post(
      URL + "events/get_event",
      { device_token: "WebApp" },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const DeleteEventAPI = async (event_id, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "events", {
      data: event_id,
      headers: { Authorization: `Bearer ${access_token}` },
    });
    notificationHandler("Event Deleted  Successfully", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};
const EditEventAPI = async (data, access_token, dispatch) => {
  try {
    const response = await axios.put(URL + "events", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    notificationHandler("New Event is Added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);

    return null;
  }
};

export {
  AddNewEventAPI,
  UploadEventMediaAPI,
  GetEventDetailsAPI,
  GetAllEventAPI,
  DeleteEventAPI,
  EditEventAPI,
};
