import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "templates/Tables/DataTable";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { UserContext } from "App";
import { useMaterialUIController } from "context";
import { GetAllDevoteeDataAPI } from "APIs/DevoteeAPIs";
import { useNavigate } from "react-router-dom";
import { notify } from "App";
import MDPagination from "components/MDPagination";
import { Icon } from "@mui/material";
import MDInput from "components/MDInput";
import { FindDevoteeAPI } from "APIs/DevoteeAPIs";
const Devotee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [devotees, setDevotees] = useState([]);
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const [rows, setRow] = useState([]);
  const [columns, setColumn] = useState([]);
  const { darkMode, sidenavColor } = controller;
  const [currentPage, setCurrentPage] = useState(1);
  const [findText, setFindText] = useState("");
  const [totalEntries, setTotalEntries] = useState(0);
  const getDevoteeDataFunc = async () => {
    const response = await GetAllDevoteeDataAPI(userData.access_token, currentPage);
    console.log(response);
    if (response.is_success) {
      setDevotees(response.data.list);
      setTotalEntries(response.data.total_page);
    } else {
      notify(response.data.message);
      setTotalEntries(0);
      setDevotees([]);
    }
  };
  useEffect(() => {
    if (userData?.access_token !== undefined) {
      getDevoteeDataFunc();
    }
  }, [userData]);
  useEffect(() => {
    setRow([
      ...devotees.map((item) => {
        return {
          pic: <MDAvatar className="bg-dark" src={item.picture} alt="Avatar" size="md" />,
          name: `${item.first_name} ${item.last_name}`,
          phone: `${item.phone}`,
          email: `${item.email}`,
          action: (
            <>
              <MDButton
                className="me-2"
                color={sidenavColor}
                onClick={() => {
                  navigate(`edit/${item.devotee_id}`);
                }}
              >
                Edit
              </MDButton>
              <MDButton
                color={sidenavColor}
                onClick={() => {
                  navigate(`view/${item.devotee_id}`);
                }}
              >
                view
              </MDButton>
            </>
          ),
        };
      }),
    ]);
    setColumn([
      { Header: "pic", accessor: "pic", width: "10%", align: "center" },
      { Header: "name", accessor: "name", width: "15%" },
      { Header: "phone", accessor: "phone", width: "15%" },
      { Header: "email", accessor: "email", width: "15%" },
      { Header: "action", accessor: "action", width: "15%", align: "center" },
    ]);
  }, [devotees, userData]);

  const onNextPage = () => {
    if (totalEntries !== currentPage) {
      setCurrentPage((prev) => prev + 1);
      getDevoteeDataFunc();
    }
  };
  const findDevoteeDataFunc = async (input) => {
    const response = await FindDevoteeAPI(input, currentPage, userData.access_token);
    if (response.is_success) {
      setDevotees(response.data.list);
      setTotalEntries(response.data.total_page);
    } else {
      notify(response.message);
      setDevotees([]);
      setTotalEntries(0);
    }
  };
  const onSearch = (input) => {
    setCurrentPage((prev) => 1);
    if (input == "") getDevoteeDataFunc();
    else findDevoteeDataFunc(input);
  };

  const onPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      getDevoteeDataFunc();
    }
  };

  return (
    <>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                px={2}
                variant="contain"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
                className="d-flex justify-content-between align-items-center pt-1 pb-1"
              >
                <MDTypography variant="h4" color="white">
                  Devotee Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  isLoading={devotees.length == 0}
                  table={{
                    columns,
                    rows,
                  }}
                  AddButton={
                    <MDButton
                      color={sidenavColor}
                      onClick={() => {
                        navigate("/devotee/add");
                      }}
                    >
                      <MDTypography fontSize={15} fontWeight="bold" color="inherit">
                        Add New Devotee
                      </MDTypography>
                    </MDButton>
                  }
                  FindText={
                    <MDInput
                      placeholder="Search..."
                      value={findText}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setFindText(e.target.value);
                        onSearch(e.target.value);
                      }}
                    />
                  }
                  NextButton={
                    <MDPagination item onClick={() => onNextPage()}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                    </MDPagination>
                  }
                  PrevButton={
                    <MDPagination item onClick={() => onPrevPage()}>
                      <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                    </MDPagination>
                  }
                  totalEntries={
                    <MDTypography fontSize={15} className="m-3">
                      {currentPage < totalEntries ? `${currentPage} / ${totalEntries}` : 1}{" "}
                    </MDTypography>
                  }
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default Devotee;
