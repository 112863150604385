import React, { useContext, useState } from "react";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useParams, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { AddAddressAPI } from "APIs/DevoteeAPIs";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
const AddDevoteeAddress = () => {
  const { id } = useParams();
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    devotee_id: id,
    title: "",
    address_line_1: "",
    address_line_2: "",
    landmark: "",
    area: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    phone: "",
  });
  const handleOnChange = (field, value) => {
    setAddress({ ...address, [field]: value });
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const response = await AddAddressAPI(address, userData.access_token, dispatch);
    if (response.is_success) {
      navigate(`/devotee/view/${id}`);
    }
    notify(response.message);
  };
  return (
    <Grid container>
      <Grid item lg={9}>
        <Card>
          <MDTypography variant="h2" className="p-3 pb-5">
            Add Address
          </MDTypography>
          <form onSubmit={handleOnSubmit}>
            <Grid container spacing={2}>
              {Object.keys(address)?.map((items) => {
                return (
                  <>
                    {[
                      "address_line_1",
                      "address_line_2",
                      "area",
                      "city",
                      "country",
                      "landmark",
                      "phone",
                      "pincode",
                      "state",
                      "title",
                    ].includes(items) ? (
                      <>
                        <Grid item xs={4} className="d-flex justify-content-between">
                          <MDTypography textTransform="capitalize" className="ps-3" fontSize={17}>
                            {items.split("_").join(" ")}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={8}>
                          <div style={{ width: "70%" }}>
                            <MDInput
                              align="left"
                              textTransform="capitalize"
                              className=""
                              fontSize={15}
                              size="lg"
                              fullWidth={true}
                              value={address[items]}
                              onChange={(event) => {
                                handleOnChange(items, event.target.value);
                              }}
                              required
                              type={items == "phone" || items == "pincode" ? "number" : "text"}
                            />
                          </div>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}

              <Grid item xs={12}>
                <MDBox width="80%" align="end">
                  <MDButton type="submit" className="mb-3" color={"success"}>
                    Save Address
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddDevoteeAddress;
