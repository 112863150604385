import React, { useContext, useState } from "react";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddNewAlbum } from "APIs/AlbumAPIs";
import { uploadAlbumMedia } from "APIs/AlbumAPIs";
const AddAlbum = () => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const [album, setAlbum] = useState({});
  const [file, setFile] = useState([]);
  const handleAlbumDataChange = (e) => {
    setAlbum({ ...album, [e.target.name]: e.target.value });
  };
  const handleFileChange = (e) => {
    setFile(e.target.files);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const AlbumResponse = await AddNewAlbum(album, userData.access_token, dispatch);
    if (AlbumResponse !== null) {
      let formData = new FormData();
      formData.append("media_album_id", AlbumResponse.media_album_id);
      for (var i in file) {
        formData.append("files", file[i]);
      }
      const uploadMedia = await uploadAlbumMedia(formData, userData.access_token, dispatch);
      if (uploadMedia !== null) {
        navigate("/album");
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} lg={9}>
        <Card>
          {" "}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} px={3} pt={2}>
              <Grid item xs={12}>
                <MDTypography variant="h2">Add Album</MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                Title
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
              >
                <MDInput
                  required
                  value={album?.media_album_title}
                  name="media_album_title"
                  type="text"
                  fullWidth={true}
                  onChange={handleAlbumDataChange}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                Media
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                lg={9}
                className="d-flex justify-content-start align-items-center"
                style={{ position: "relative" }}
              >
                {/* <MDButton
                  color={sidenavColor}
                  className="me-3"
                  style={{ zIndex: 0, width: "150px" }}
                >
                  Select File{" "}
                </MDButton> */}
                <input
                  required
                  type="file"
                  name="image"
                  multiple
                  accept=".png,.jpg,.jpeg,.gif,.webp,.tiff,.psd,
                  .bmp,.ico/.icon/x-icon"
                  onChange={handleFileChange}
                  //   style={{ opacity: 0, position: "absolute", zIndex: 1, width: "150px" }}
                />
                {/* <MDTypography variant="body2">
                  {file.length > 0 ? `Selected files - ${file.length}` : ""}
                </MDTypography> */}
              </Grid>
              <Grid item xs={12} align="end" my={3}>
                <MDButton color="success" type="submit">
                  Save Album
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddAlbum;
