/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import Devotee from "./layouts/devotee";
import AddDevotee from "./layouts/devotee/components/addDevotee";
import ViewDevotee from "./layouts/devotee/components/viewDevotee";
import EditDevotee from "./layouts/devotee/components/editDevotee";
import AddDevoteeAddress from "./layouts/devotee/components/addDevoteeAddress";
import Blog from "./layouts/blog";
import EditBlog from "layouts/blog/components/editBlog";
import AddBlog from "layouts/blog/components/addBlog";
import ViewBlog from "layouts/blog/components/viewBlog";
import Event from "layouts/event";
import ViewEvent from "layouts/event/components/viewEvent";
import EditEvent from "layouts/event/components/editEvent";
import AddEvent from "layouts/event/components/addEvent";
import Media from "layouts/media";
import EditMedia from "layouts/media/components/editMedia";
import AddMedia from "layouts/media/components/addMedia";
import ViewMedia from "layouts/media/components/viewMedia";
import ViewAlbum from "layouts/media/components/viewMedia";
import Literature from "layouts/literature";
import EditLiterature from "layouts/literature/components/EditLiterature";
import AddLiterature from "layouts/literature/components/addLiterature";
import Audio from "layouts/audio";
import EditAudio from "layouts/audio/components/editAudio";
import AddAudio from "layouts/audio/components/addAudio";
import ViewAudio from "layouts/audio/components/viewAudio";
import Seva from "layouts/seva";
import ViewSeva from "layouts/seva/components/viewSeva";
import EditSeva from "layouts/seva/components/editSeva";
import AddSeva from "layouts/seva/components/addSeva";
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },

  {
    type: "collapse",
    name: "Devotee",
    key: "devotee",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/devotee",
    component: <Devotee />,
  },
  {
    name: "Add devotee",
    key: "addDevotee",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/devotee/add",
    component: <AddDevotee />,
  },
  {
    name: "View devotee",
    key: "viewDevotee",
    route: "/devotee/view/:devotee_id",
    component: <ViewDevotee />,
  },
  {
    name: "Edit devotee",
    key: "editDevotee",
    route: "/devotee/edit/:devotee_id",
    component: <EditDevotee />,
  },
  {
    name: "Add devotee Address",
    key: "addDevoteeAddress",
    route: "/devotee/new_address/:id",
    component: <AddDevoteeAddress />,
  },
  {
    type: "collapse",
    name: "Create Sub Admin",
    key: "createSubAdmin",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/create_sub_admin",
    component: <Devotee />,
  },
];

// {
//   type: "collapse",
//   name: "Blog",
//   key: "blog",
//   icon: <Icon fontSize="small">receipt_long</Icon>,
//   route: "/blog",
//   component: <Blog />,
// },
// {
//   name: "Edit Blog",
//   key: "editBlog",
//   route: "/blog/edit/:id",
//   component: <EditBlog />,
// },
// {
//   name: "Add Blog",
//   key: "addBlog",
//   route: "/blog/add",
//   component: <AddBlog />,
// },
// {
//   name: "View Blog",
//   key: "viewBlog",
//   route: "/blog/view/:id",
//   component: <ViewBlog />,
// },
// {
//   type: "collapse",
//   name: "Event",
//   key: "event",
//   icon: <Icon fontSize="small">table_view</Icon>,
//   route: "/event",
//   component: <Event />,
// },
// {
//   name: "View Event",
//   key: "viewEvent",
//   route: "/event/view/:id",
//   component: <ViewEvent />,
// },
// {
//   name: "Edit Event",
//   key: "editEvent",
//   route: "/event/edit/:id",
//   component: <EditEvent />,
// },
// {
//   name: "Add Event",
//   key: "addEvent",
//   route: "/event/add",
//   component: <AddEvent />,
// },
// {
//   type: "collapse",
//   name: "Album",
//   key: "album",
//   icon: <Icon fontSize="small">receipt_long</Icon>,
//   route: "/album",
//   component: <Media />,
// },
// {
//   name: "Edit Album",
//   key: "editAlbum",
//   route: "/album/edit/:id",
//   component: <EditMedia />,
// },
// {
//   name: "Add Album",
//   key: "addAlbum",
//   route: "/album/add",
//   component: <AddMedia />,
// },
// {
//   name: "View Album",
//   key: "viewAlbum",
//   route: "/album/view/:id",
//   component: <ViewAlbum />,
// },
// {
//   type: "collapse",
//   name: "Literature",
//   key: "literature",
//   icon: <Icon fontSize="small">receipt_long</Icon>,
//   route: "/literature",
//   component: <Literature />,
// },
// {
//   name: "Edit Literature",
//   key: "editLiterature",
//   route: "/literature/edit/:id",
//   component: <EditLiterature />,
// },
// {
//   name: "Add Literature",
//   key: "addliterature",
//   route: "/literature/add",
//   component: <AddLiterature />,
// },
// {
//   type: "collapse",
//   name: "Audio",
//   key: "audio",
//   icon: <Icon fontSize="small">receipt_long</Icon>,
//   route: "/audio",
//   component: <Audio />,
// },
// {
//   name: "Edit audio",
//   key: "editAudio",
//   route: "/audio/edit/:id",
//   component: <EditAudio />,
// },
// {
//   name: "Add Audio",
//   key: "addAudio",
//   route: "/audio/add",
//   component: <AddAudio />,
// },
// {
//   name: "View Audio",
//   key: "addView",
//   route: "/audio/view/:id",
//   component: <ViewAudio />,
// },
// {
//   type: "collapse",
//   name: "Seva",
//   key: "seva",
//   icon: <Icon fontSize="small">table_view</Icon>,
//   route: "/seva",
//   component: <Seva />,
// },
// {
//   name: "View Seva",
//   key: "viewSeva",
//   route: "/seva/view/:id",
//   component: <ViewSeva />,
// },
// {
//   name: "Edit Seva",
//   key: "editSeva",
//   route: "/seva/edit/:id",
//   component: <EditSeva />,
// },
// {
//   name: "Add Seva",
//   key: "addSeva",
//   route: "/seva/add",
//   component: <AddSeva />,
// },
export default routes;
