/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material Dashboard 2 React example components
import TimelineItem from "templates/Timeline/TimelineItem";

const OrdersOverview = (event) => {
  const [data, setData] = useState({});
  useEffect(() => {
    console.log(event.event);
    setData({ ...event });
  }, [event]);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {data?.event?.event_title}
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {data?.event?.event_start_date.slice(0, 10).split("-").reverse().join("-")} →{" "}
            {data?.event?.event_end_date.slice(0, 10).split("-").reverse().join("-")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {data?.event?.schedules?.map((item, key) => {
          return (
            <TimelineItem
              key={key}
              color="dark"
              icon="chevron_right"
              title={item?.event_schedule_title}
              dateTime={
                item?.event_schedule_time.slice(11, 16) +
                "  ,  " +
                item?.event_schedule_date?.slice(0, 10).split("-").reverse().join("-")
              }
            />
          );
        })}
      </MDBox>
    </Card>
  );
};
OrdersOverview.prototypes = {
  event: PropTypes.object,
};

export default OrdersOverview;
