import { UserContext } from "App";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { GetSevaDetails } from "APIs/SevaAPIs";
import DataTable from "templates/Tables/DataTable";
import ReactPlayer from "react-player";
import DOMPurify from "dompurify";
const EditSeva = () => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [sevaData, setSevaData] = useState({});
  const [donationData, setDonationData] = useState([]);
  const [mediaList, setMediaList] = useState();

  const mediaListSeparation = () => {
    var temp = { photo: [], video: [] };
    for (var i of sevaData?.media_list) {
      if (i.media_type == 1) {
        temp["photo"].push(i);
      } else {
        temp["video"].push(i);
      }
    }
    console.log(temp);
    setMediaList({ ...temp });
  };
  const getSevaDetailsFunc = async (id, access_token, dispatch) => {
    const response = await GetSevaDetails(id, access_token, dispatch);
    if (response !== null) {
      setSevaData({
        seva_id: response.seva_id,
        seva_title: response.seva_title,
        seva_description: response.seva_description,
        media_list: response.media_list,
      });
      setDonationData([...response.donation_list]);
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getSevaDetailsFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  useEffect(() => {
    if (sevaData.media_list !== undefined) {
      mediaListSeparation();
    }
  }, [sevaData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          {sevaData !== undefined ? (
            <Grid container spacing={2} py={2} px={2}>
              <Grid item lg={3} md={5} xs={12} alignContent="center">
                <img
                  style={{ width: "100%", borderRadius: "10px" }}
                  src={
                    mediaList?.photo !== undefined || mediaList?.photo.length > 0
                      ? mediaList?.photo[0]?.media
                      : ""
                  }
                />
              </Grid>
              <Grid item lg={9} md={7} xs={12}>
                <MDTypography variant="h1">{sevaData?.seva_title}</MDTypography>
                <MDTypography
                  variant="body1"
                  className="ms-5 mt-3"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(sevaData?.seva_description),
                  }}
                ></MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography fontSize={30} variant="inherit" className="ms-3">
                  {" "}
                  Donations
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
              </Grid>
              <Grid item xs={12}>
                <DataTable
                  table={{
                    rows: [
                      ...donationData?.map((item, key) => {
                        return {
                          sr: <MDTypography variant="body2">{key + 1}</MDTypography>,
                          title: (
                            <MDTypography variant="body2">{item?.donation_title}</MDTypography>
                          ),
                          amount: (
                            <MDTypography variant="body2">₹{item?.donation_amount}</MDTypography>
                          ),
                        };
                      }),
                    ],
                    columns: [
                      { Header: "Sr No", accessor: "sr", align: "center", width: "26%" },
                      { Header: "title", accessor: "title", align: "center" },
                      { Header: "amount", accessor: "amount", align: "center" },
                    ],
                  }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                  // canSearch={true}
                  entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
                  // pagination={{ variant: "contained", color: sidenavColor }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography
                  fontSize={25}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  Photos
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
                <div style={{ columns: "2" }}>
                  {mediaList?.photo?.map((item, key) => {
                    return (
                      <div style={{ display: "flex", paddingTop: "15px" }} key={key}>
                        <a target="blank" href={item?.media}>
                          <img style={{ width: "100%", borderRadius: "10px" }} src={item?.media} />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <MDTypography
                  fontSize={25}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  Videos
                </MDTypography>
                <hr
                  style={{
                    backgroundColor: "#7b809a",
                  }}
                ></hr>
                {mediaList?.video?.map((item, key) => {
                  return (
                    <div className="d-flex justify-content-center p-3" key={key}>
                      {/* <img style={{ width: "100%", borderRadius: "10px" }} src={item.media} /> */}
                      {ReactPlayer.canPlay(item.media) ? (
                        <ReactPlayer
                          controls={true}
                          url={item.media}
                          style={{ width: "100%", borderRadius: "10px" }}
                          alt={item.media}
                        />
                      ) : (
                        <a href={item.media}>Click here for video</a>
                      )}
                    </div>
                  );
                })}
              </Grid>
            </Grid>
          ) : (
            <div className="d-flex justify-content-center align-item-center h-100">
              <span className="spinner-grow" role="status"></span>
            </div>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditSeva;
