import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from "App";
import { Grid, Select, MenuItem, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { UploadDevoteeImageAPI } from "APIs/DevoteeAPIs";
import { Loader } from "components/Custom/Loader";
import { SelectDropDown } from "components/Custom/SelectDropDown";
import { notify } from "App";
const EditDevoteeDetails = ({
  data,
  handleDataChange,
  currentSelected,
  setCurrentSelected,
  countryList,
}) => {
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    formData.append("devotee_id", data.devotee_id);
    formData.append("files", e.target.files[0]);
    const response = await UploadDevoteeImageAPI(formData, userData.access_token);
    notify(response.message);
    window.location.reload();
  };

  return (
    <>
      {data ? (
        <Grid item xs={12}>
          <Grid container className="mb-3 d-flex justify-content-center">
            <Grid item xxl={3} md={4} sm={6} className="ps-4">
              <div
                style={{
                  position: "relative",
                  display: " inline-block",
                }}
              >
                <img
                  onError="https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/user.png"
                  style={{
                    borderRadius: "100%",
                    width: "170px",
                    height: "170px",
                    backgroundColor: "white",
                  }}
                  src={
                    data.picture && data.picture[data.picture.length - 1] !== "/"
                      ? data.picture
                      : "https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/user.png"
                  }
                  alt="Avatar"
                />
                <MDButton
                  color={darkMode ? "dark" : "white"}
                  size="small"
                  style={{
                    width: "35px",
                    height: "60px",
                    position: "absolute",
                    bottom: -15,
                    right: -15,
                    borderRadius: "100%",
                    cursor: "pointer",
                    // padding: "5% 5% 5% 5%",
                    // margin: "0",
                  }}
                >
                  <img
                    style={{ width: "100%" }}
                    src={
                      darkMode
                        ? "https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/edit-image-light.png"
                        : "https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/edit-image-dark.png"
                    }
                  />
                </MDButton>
                <MDInput
                  style={{
                    width: "50px",
                    height: "90px",
                    position: "absolute",
                    bottom: -40,
                    right: 0,
                    opacity: 0,
                    cursor: "pointer",
                    zIndex: 1000,
                    // padding: "5% 5% 5% 5%",
                    // margin: "0",
                  }}
                  type="file"
                  onChange={(e) => {
                    handleFileUpload(e);
                  }}
                />
              </div>
            </Grid>
            <Grid item xxl={9} md={8} sm={6} className="d-flex align-items-center">
              <div>
                <MDTypography
                  fontSize={30}
                  fontWeight={"regular"}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >{`${data?.first_name} ${data?.last_name}`}</MDTypography>
                <MDTypography
                  fontSize={17}
                  fontWeight={"regular"}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  <a href={`mailto:${data?.email}`}>{data?.email}</a>
                </MDTypography>{" "}
                <MDTypography
                  fontSize={17}
                  fontWeight={"regular"}
                  variant="inherit"
                  className="d-flex justify-content-center"
                >
                  {data?.comments}
                </MDTypography>{" "}
              </div>
            </Grid>
          </Grid>
          <MDTypography fontSize={30} variant="inherit" className="ms-3">
            Details
          </MDTypography>
          <hr
            style={{
              backgroundColor: "#7b809a",
            }}
          ></hr>
        </Grid>
      ) : (
        <Loader />
      )}
      {Object.keys(data)?.map((items, key) => {
        return (
          <>
            {[
              "first_name",
              "last_name",
              "anniversary_date",
              "birth_date",
              "email",
              "gender",
              "middle_name",
              "phone",
              "country_code",
              "comments",
            ].includes(items) ? (
              <>
                <Grid item xs={6} md={3} className="d-flex justify-content-between">
                  <MDTypography textTransform="capitalize" className="ps-3" fontSize={17}>
                    {items.split("_").join(" ")}
                  </MDTypography>
                </Grid>
                <Grid item xs={6} md={9} key={key}>
                  {items == "gender" ? (
                    <div style={{ width: "70%", height: "45px", position: "relative" }}>
                      <Select
                        className="w-100 h-100"
                        value={data[items]}
                        onChange={(e) => {
                          handleDataChange(items, e.target.value);
                        }}
                      >
                        <MenuItem value={0}>Male</MenuItem>
                        <MenuItem value={1}>Female</MenuItem>
                      </Select>
                      <ArrowDropDownIcon
                        fontSize="large"
                        style={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  ) : items == "country_code" ? (
                    <>
                      {countryList.length > 0 ? (
                        <div style={{ width: "70%" }}>
                          <SelectDropDown
                            currentSelected={currentSelected}
                            setCurrentSelected={(e) => {
                              setCurrentSelected(e);
                            }}
                            data={countryList}
                            defautlOptions={{
                              value: data.country_code,
                              label: `${data.phone_code || "Select Country Code"}`,
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div style={{ width: "70%" }}>
                      <MDInput
                        required={
                          items == "first_name" || items == "phone" || items == "last_name"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          handleDataChange(items, e.target.value.split("-").reverse().join("-"));
                        }}
                        type={
                          items == "birth_date" || items == "anniversary_date"
                            ? "date"
                            : items == "phone"
                            ? "number"
                            : items == "email"
                            ? "email"
                            : "text"
                        }
                        align="left"
                        textTransform="capitalize"
                        className=""
                        fontSize={15}
                        size="lg"
                        fullWidth={true}
                        value={
                          items == "birth_date" || items == "anniversary_date"
                            ? `${data[items]}`.split("-").reverse().join("-")
                            : data[items]
                        }
                      />
                    </div>
                  )}
                </Grid>
              </>
            ) : (
              ""
            )}
          </>
        );
      })}

      <br></br>
    </>
  );
};
EditDevoteeDetails.propTypes = {
  data: PropTypes.object,
  handleDataChange: PropTypes.func,
  userErrors: PropTypes.arrayOf([
    PropTypes.shape({
      location: PropTypes.string,
      msg: PropTypes.string,
      path: PropTypes.string,
      type: PropTypes.string,
    }),
  ]),
  currentSelected: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setCurrentSelected: PropTypes.func,
  countryList: PropTypes.array,
};

export default EditDevoteeDetails;
