/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, createContext, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "templates/Sidenav";
import Configurator from "templates/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import GetCookies from "Cookies/getCookies";
import { AutoLogin } from "APIs/UserAuthenticationAPIs";
import SignIn from "layouts/authentication/sign-in";
import DashboardNavbar from "templates/Navbars/DashboardNavbar";
import DashboardLayout from "templates/LayoutContainers/DashboardLayout";

import { useSelector, useDispatch } from "react-redux";
import useLocalStorage from "hooks/useLocalStorage";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
export const UserContext = createContext();

import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  useEffect(() => {
    notify("🦄 Welcome Back Admin!");
  }, []);
  const [userData, setUserData] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [localStorageData, setLocalStorageData] = useLocalStorage("userData", null);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  };
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const HandleAuthenticaton = async (access_token) => {
    const response = await AutoLogin(access_token);
    if (response !== null) {
      setIsLoggedIn(true);
      setLocalStorageData(response);
      setUserData((prev) => {
        return response;
      });
    } else {
      localStorage.removeItem("userData");
      setIsLoggedIn(false);
      setIsLoading(false);
      navigate("authentication/sign-in");
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (localStorageData == null) {
      const access_token = GetCookies("userData");
      if (access_token !== undefined) {
        HandleAuthenticaton(JSON.parse(access_token));
      } else {
        navigate("authentication/sign-in");
        setIsLoading(false);
      }
      // location.reload();
    } else {
      setUserData((prev) => {
        return localStorageData;
      });
      setIsLoggedIn(true);
      setIsLoading(false);
    }
  }, [isLoggedIn]);

  const notification = useSelector((state) => state.notification.notificationBars);
  return (
    <>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-item-center h-100">
            <span className="spinner-grow" role="status"></span>
          </div>
        }
      >
        <UserContext.Provider value={userData}>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              transition={Bounce}
            />

            {isLoggedIn ? (
              <>
                <CssBaseline />

                <Sidenav
                  color={sidenavColor}
                  brand="https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/Shriyantra-02.png"
                  brandName="RanuApp"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <DashboardLayout>
                  <DashboardNavbar />
                  <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to={"/dashboard"} />} />
                  </Routes>
                  <Configurator />
                  {configsButton}
                  <div
                    style={{
                      zIndex: 100000000,
                      position: "fixed",
                      top: 10,
                      left: "50%",
                      transform: "translate(-50%, 0)",
                    }}
                  >
                    {notification?.map((item, key) => {
                      return (
                        <MDBox key={key}>
                          <MDAlert
                            color={item.color}
                            className="mt-2"
                            dismissible
                            style={{ height: "40px", zIndex: 10000000, minWidth: "350px" }}
                          >
                            <Icon fontSize="large">
                              {" "}
                              {item.color == "success" ? " done " : item.color}{" "}
                            </Icon>{" "}
                            <pre> </pre>
                            <MDTypography color="white" fontSize="auto" fullWidth>
                              {item.text}
                            </MDTypography>
                          </MDAlert>
                        </MDBox>
                      );
                    })}
                  </div>
                </DashboardLayout>
              </>
            ) : (
              <>
                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="spinner-grow"></div>
                  </div>
                ) : (
                  <Routes>
                    <Route
                      path="*"
                      element={
                        <SignIn
                          ChangeLoggedIn={(e) => {
                            setIsLoggedIn(e);
                          }}
                        />
                      }
                    />
                  </Routes>
                )}
              </>
            )}
          </ThemeProvider>
        </UserContext.Provider>
      </Suspense>
    </>
  );
}

export const notify = (text, theme) => {
  toast(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light" || theme,
    transition: Bounce,
  });
};
