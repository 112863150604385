import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserContext } from "App";
import Card from "@mui/material/Card";
import { Grid, Select, MenuItem, FormControl } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import EditDevoteeDetails from "./editDevoteeDetails";
import EditDevoteeAddress from "./editDevoteeAddress";
import {
  GetDevoteeDataAPI,
  GetDevoteeAddressAPI,
  EditDevoteDataAPI,
  EditDevoteeAddressAPI,
} from "APIs/DevoteeAPIs";
import { notificationHandler } from "reducers/notificationSlice";
import { popUp } from "components/Custom/AreYouSurePopUp";
import { notify } from "App";
import { GetCountryCodeList } from "APIs/CommonAPI";
const EditDevotee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [devoteeData, setDevoteeData] = useState({});
  const [devoteeAddress, setDevoteeAddress] = useState();
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;
  const { devotee_id } = useParams();
  const handleDeleteChanges = async (address_id) => {
    setDevoteeAddress([...devoteeAddress.filter((item) => item.devotee_address_id !== address_id)]);
  };
  const getDevoteeDataAPIFunc = async (devotee_id, access_token, dispatch) => {
    const data = await GetDevoteeDataAPI(devotee_id, access_token, dispatch);
    if (data.is_success) {
      setDevoteeData({
        devotee_id: data.data.devotee_id?.toString(),
        first_name: data.data.first_name?.toString(),
        middle_name: data.data.middle_name?.toString(),
        last_name: data.data.last_name?.toString(),
        email: data.data.email?.toString(),
        gender: data.data.gender?.toString(),
        phone: data.data.phone?.toString(),
        country_code: data.data.country_code?.toString(),
        birth_date: data.data.birth_date?.toString(),
        anniversary_date: data.data.anniversary_date?.toString(),
        comments: data.data.comments?.toString(),
        picture: data.data.picture?.toString(),
      });
    } else {
      notify(data.message);
    }
  };
  const getDevoteeAddressAPIGetDevoteeAddressAPIFunc = async (
    devotee_id,
    access_token,
    dispatch
  ) => {
    const response = await GetDevoteeAddressAPI(devotee_id, access_token, dispatch);
    if (response.is_success) {
      setDevoteeAddress([
        ...response.data.list.map((data) => {
          let temp = {};

          if (data.devotee_address_id) {
            temp.devotee_address_id = data.devotee_address_id.toString();
          }
          if (data.devotee_id) {
            temp.devotee_id = data.devotee_id.toString();
          }
          if (data.title) {
            temp.title = data.title.toString();
          }
          if (data.address_line_1) {
            temp.address_line_1 = data.address_line_1.toString();
          }
          if (data.address_line_2) {
            temp.address_line_2 = data.address_line_2.toString();
          }
          if (data.landmark) {
            temp.landmark = data.landmark.toString();
          }
          if (data.area) {
            temp.area = data.area.toString();
          }
          if (data.city) {
            temp.city = data.city.toString();
          }
          if (data.state) {
            temp.state = data.state.toString();
          }
          if (data.country) {
            temp.country = data.country.toString();
          }
          if (data.pincode) {
            temp.pincode = data.pincode.toString();
          }
          if (data.phone) {
            temp.phone = data.phone.toString();
          }

          return temp;
        }),
      ]);
    } else {
      notify(data.message);
    }
  };
  useEffect(() => {
    if (userData.access_token) {
      getDevoteeDataAPIFunc(devotee_id, userData.access_token, dispatch);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.access_token && devoteeData?.devotee_id) {
      getDevoteeAddressAPIGetDevoteeAddressAPIFunc(
        devoteeData.devotee_id,
        userData.access_token,
        dispatch
      );
    }
  }, [devoteeData, userData]);

  const handleDevoteeDataChange = (field, value) => {
    setDevoteeData({
      ...devoteeData,
      [field]: value,
    });
  };
  const handleAddressChange = (index, field, value) => {
    let tempData = devoteeAddress[index];
    tempData = { ...tempData, [field]: value };
    let tempArray = devoteeAddress;
    tempArray[index] = tempData;
    setDevoteeAddress([...tempArray]);
  };
  const handleEditSubmit = async () => {
    var temp = {
      ...devoteeData,
      country_code: currentSelected.value,
    };
    let devoteeResponse = await EditDevoteDataAPI(temp, userData.access_token);

    let addressResponse = await devoteeAddress.map(async (data) => {
      return await EditDevoteeAddressAPI(data, userData.access_token).is_success;
    });
    Promise.all(addressResponse).then((data) => {
      if (data.includes(false) || !devoteeResponse.is_success) {
        notify("Something went wrong");
      } else {
        navigate(`/devotee/view/${devotee_id}`);
        notify("Devotee Added successfully");
      }
    });
  };

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    getCountryCode();
  }, [userData]);

  const getCountryCode = async () => {
    const response = await GetCountryCodeList(userData.access_token, dispatch);
    setCountryList(
      response.map((value) => {
        return {
          value: value.country_currency_id,
          label: `${value.country_name}  :  ${value.phone_code}`,
        };
      })
    );
  };

  const [currentSelected, setCurrentSelected] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    if (devoteeData !== undefined && countryList.length > 0) {
      let tempArray = countryList.filter((value) => value.value == devoteeData.country_code);
      console.log(countryList, tempArray, devoteeData);
      if (tempArray.length > 0)
        setCurrentSelected({
          value: devoteeData.country_code,
          label: `${tempArray[0].label}`,
        });
      else
        setCurrentSelected({
          value: 112,
          label: `INDIA  :  +91`,
        });
    }
  }, [devoteeData, countryList]);

  return (
    <Grid container>
      <Grid item xs={12} lg={9}>
        <Card>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await popUp(handleEditSubmit);
            }}
          >
            <MDBox color={darkMode ? "white" : "Dark"} borderRadius="lg" shadow="xxl" p={2}>
              {devoteeData ? (
                <Grid container spacing={2} className="mt-3">
                  <EditDevoteeDetails
                    data={devoteeData}
                    handleDataChange={handleDevoteeDataChange}
                    setCurrentSelected={(e) => {
                      setCurrentSelected(e);
                    }}
                    currentSelected={currentSelected}
                    countryList={countryList}
                  />
                  <Grid item xs={12} className="d-flex justify-content-start align-items-center">
                    <MDTypography fontSize={30} variant="inherit" className="" p={2}>
                      Addresses{" "}
                    </MDTypography>
                    <MDButton
                      color={sidenavColor}
                      onClick={() => {
                        navigate("/devotee/new_address/" + devoteeData.devotee_id);
                      }}
                    >
                      Add New Address
                    </MDButton>
                  </Grid>
                  <hr
                    style={{
                      backgroundColor: "#7b809a",
                    }}
                  ></hr>
                  <EditDevoteeAddress
                    devoteeAddress={devoteeAddress}
                    handleDataChange={handleAddressChange}
                    handleDeleteChanges={handleDeleteChanges}
                  />
                  <Grid item xs={12}>
                    <MDBox width="100%" align="end" pt={3}>
                      <MDButton color="success" type="submit">
                        save devotee
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              ) : (
                <div className="d-flex justify-content-center align-item-center h-100">
                  <span className="spinner-grow" role="status"></span>
                </div>
              )}
            </MDBox>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditDevotee;
