import Cookies from "js-cookie";

const SetCookies = (cookieName, value) => {
  Cookies.set(cookieName, value, {
    expires: 7,
    path: "",
  });
};

export default SetCookies;
