import React, { useContext, useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { UserContext } from "App";
import { useDispatch } from "react-redux";
import { GetBlogDetails } from "APIs/BlogAPIs";
import { useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
const ViewBlog = () => {
  const userData = useContext(UserContext);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [blogDetails, setBlogDetails] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const getBlogDetailsFunc = async (id, access_token, dispatch) => {
    const response = await GetBlogDetails(id, access_token, dispatch);
    if (response !== null) {
      setBlogDetails({ ...response });
    }
  };
  useEffect(() => {
    if (userData.access_token !== undefined) {
      getBlogDetailsFunc(id, userData.access_token, dispatch);
    }
  }, [userData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={2} px={3}>
            <Grid item xs={12} className="d-flex justify-content-center">
              <MDBox
                p={2}
                style={{
                  width: "70%",
                  height: "60vh",
                }}
              >
                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div className="carousel-indicators">
                    <button
                      className="active"
                      aria-current="true"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      aria-label={`Slide 1`}
                    ></button>
                    {blogDetails?.image_list.slice(1).map((items, key) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={key + 1}
                          aria-label={`Slide ${key + 2}`}
                        ></button>
                      );
                    })}
                  </div>
                  <div
                    className="carousel-inner"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      className="carousel-item active "
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <a target="blank" href={blogDetails?.image_list[0].media}>
                        <img
                          src={blogDetails?.image_list[0].media}
                          className="d-block w-100 "
                          alt="..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: " contain",
                          }}
                        />
                      </a>
                    </div>
                    {blogDetails?.image_list.slice(1).map((items, key) => {
                      return (
                        <div
                          className="carousel-item"
                          key={key}
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <a target="blank" href={items.media}>
                            <img
                              src={items.media}
                              className="d-block w-100 "
                              alt="..."
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: " contain",
                              }}
                            />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <MDTypography fontSize={40} color={darkMode ? "white" : "dark"}>
                      {" "}
                      &lt;
                    </MDTypography>

                    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span> */}
                  </button>
                  <button
                    className="carousel-control-next "
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <MDTypography fontSize={40} color={darkMode ? "white" : "dark"}>
                      {" "}
                      &gt;
                    </MDTypography>
                    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span> */}
                  </button>
                </div>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <div className="container">
                <MDTypography variant="h1" my={3} className="d-flex justify-content-center">
                  {blogDetails?.blog_title}
                </MDTypography>
                <MDTypography
                  className="lh-lg"
                  variant="body1"
                  style={{
                    textAlign: "justify",
                    textJustify: "inter-word",
                    paddingBottom: "50px",
                  }}
                  dangerouslySetInnerHTML={{ __html: `${blogDetails?.blog_description}` }}
                ></MDTypography>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ViewBlog;
