import axios from "axios";
import { URL } from "./MainAPILink";
import CatchMessage from "./catchMessage";
import { notificationHandler } from "reducers/notificationSlice";

const GetAllSeva = async (access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "seva", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const GetSevaDetails = async (seva_id, access_token, dispatch) => {
  try {
    const reqURL = URL + "seva?seva_id=" + seva_id;
    const response = await axios.get(reqURL, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    // console.log(response.data)
    return response.data.data;
  } catch (err) {
    console.log(err);
    CatchMessage(err, dispatch);
    return null;
  }
};

const AddNewSeva = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "seva", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Seva Added", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const EditSevaApi = async (data, access_token, dispatch) => {
  try {
    const response = await axios.put(URL + "seva", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Seva Edited", "success", dispatch);
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const UploadSeva = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "seva/upload", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};

const DeleteSeva = async (data, access_token, dispatch) => {
  try {
    const response = await axios.delete(URL + "seva", {
      data: data,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Seva Deleted", "success", dispatch);
    return response.data;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
export { GetAllSeva, GetSevaDetails, AddNewSeva, EditSevaApi, UploadSeva, DeleteSeva };
