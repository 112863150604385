import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserContext } from "App";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { DeleteAddressAPI } from "APIs/DevoteeAPIs";
const EditDevoteeAddress = ({ devoteeAddress, handleDataChange, handleDeleteChanges }) => {
  const userData = useContext(UserContext);
  const dispatch = useDispatch();
  const handleDelete = async (address_id) => {
    const response = await DeleteAddressAPI(address_id, userData.access_token, dispatch);
    if (response !== null) {
      handleDeleteChanges(address_id);
    }
  };
  return (
    <>
      {devoteeAddress?.map((addresses, key) => {
        return (
          <Grid container spacing={2} className="mt-3" key={key}>
            {[
              "title",
              "phone",
              "address_line_1",
              "address_line_2",
              "area",
              "city",
              "country",
              "landmark",
              "pincode",
              "state",
            ].map((items) => (
              <>
                <Grid item xs={6} lg={2} className="d-flex justify-content-between">
                  <MDTypography textTransform="capitalize" className="ps-3" fontSize={17}>
                    {items.split("_").join(" ")}
                  </MDTypography>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <div style={{ width: "70%" }}>
                    <MDInput
                      required={items == "phone" || items == "landmark" ? false : true}
                      align="left"
                      textTransform="capitalize"
                      className=""
                      fontSize={15}
                      size="lg"
                      fullWidth={true}
                      value={addresses[items]}
                      onChange={(event) => {
                        handleDataChange(key, items, event.target.value);
                      }}
                    />
                  </div>
                </Grid>
              </>
            ))}

            <MDBox className="d-flex justify-content-end pt-3" width="90%">
              <MDButton
                color="error"
                onClick={async () => {
                  await popUp(handleDelete, addresses.devotee_address_id);
                }}
              >
                Delete
              </MDButton>
            </MDBox>
          </Grid>
        );
      })}
    </>
  );
};
EditDevoteeAddress.propTypes = {
  devoteeAddress: PropTypes.arrayOf(PropTypes.object),
  handleDataChange: PropTypes.func,
  handleDeleteChanges: PropTypes.func,
};
export default EditDevoteeAddress;
