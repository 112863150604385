import axios from "axios";
import { URL } from "./MainAPILink";
import CatchMessage from "./catchMessage";
import { notificationHandler } from "reducers/notificationSlice";

const GetAllLiteratures = async (access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "literature", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data.data.list;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const UploadLiterature = async (data, access_token, dispatch) => {
  try {
    const response = await axios.post(URL + "literature/upload", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Literature is added!", "success", dispatch);
    return true;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const DeleteLiteratures = async (id, access_token, dispatch) => {
  try {
    const response = await axios.delete(`${URL + "literature?literature_id=" + id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Literature Deleted !", "success", dispatch);
    return true;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
const EditLiteratureAPI = async (data, access_token, dispatch) => {
  try {
    // console.log(data)
    const response = await axios.put(URL + "literature/", data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    notificationHandler("Literature is edited!", "success", dispatch);
    return true;
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
export { GetAllLiteratures, UploadLiterature, DeleteLiteratures, EditLiteratureAPI };
