/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
//custom
import { Login } from "APIs/UserAuthenticationAPIs";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { notificationHandler } from "reducers/notificationSlice";
import { Icon } from "@mui/material";
import MDAlert from "components/MDAlert";

function Basic({ ChangeLoggedIn }) {
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.notificationBars);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const response = await Login({ phone: phone, password: password, dispatch: dispatch });
    if (response !== null) {
      ChangeLoggedIn(true);
      notificationHandler("logged in!", "success", dispatch);
      navigator("/");
    } else {
      notificationHandler("Phone. or password is incorrect ! ", "info", dispatch);
    }
    // console.log(response);
  };
  return (
    <BasicLayout image="https://api.minio.loweatelier.com/tuljamaa/static-images/admin-panel/bg-sign-in-basic.jpeg">
      <div
        style={{
          zIndex: 10000000,
          position: "fixed",
          top: 10,
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        {notification?.map((item, key) => {
          return (
            <MDBox key={key}>
              <MDAlert
                color={item.color}
                className="mt-2"
                dismissible
                style={{ height: "40px", zIndex: 10000000, minWidth: "350px" }}
              >
                <Icon fontSize="large"> {item.color == "success" ? " done " : item.color} </Icon>{" "}
                <pre> </pre>
                <MDTypography color="white" fontSize="auto" fullWidth>
                  {item.text}
                </MDTypography>
              </MDAlert>
            </MDBox>
          );
        })}
      </div>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              {/* <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography> */}
            </Grid>
            <Grid item xs={2}>
              {/* <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography> */}
            </Grid>
            <Grid item xs={2}>
              {/* <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography> */}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleOnSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Phone"
                fullWidth
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

Basic.propTypes = {
  ChangeLoggedIn: PropType.func,
};

export default Basic;
