import React from "react";
import { Grid, Card } from "@mui/material";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "templates/Tables/DataTable";
import MDBox from "components/MDBox";
import { popUp } from "components/Custom/AreYouSurePopUp";
const EventSchedules = ({ data, handleDataChange, deleteSchedule }) => {
  return (
    <>
      <Card>
        <MDBox>
          {" "}
          <DataTable
            table={{
              rows: [
                ...data.map((item, key) => {
                  return {
                    title: (
                      <MDInput
                        required
                        name="event_schedule_title"
                        type="text"
                        label="title"
                        value={item.event_schedule_title}
                        onChange={(e) => {
                          handleDataChange(e, key);
                        }}
                      />
                    ),
                    date: (
                      <MDInput
                        required
                        name="event_schedule_date"
                        type="date"
                        value={item.event_schedule_date.slice(0, 10)}
                        onChange={(e) => {
                          handleDataChange(e, key);
                        }}
                      />
                    ),
                    time: (
                      <MDInput
                        required
                        name="event_schedule_time"
                        type="time"
                        value={item.event_schedule_time.slice(11, 16)}
                        onChange={(e) => {
                          handleDataChange(e, key);
                        }}
                      />
                      // <MDBox>
                      //   <LocalizationProvider dateAdapter={AdapterDayjs}>
                      //     <MobileTimePicker
                      //       required
                      //       name="event_schedule_time"
                      //       color="dark"
                      //       value={item.event_schedule_time.slice(11, 16)}
                      //       onChange={(e) => {
                      //         console.log(e);
                      //         handleDataChange(e, key);
                      //       }}
                      //     />
                      //   </LocalizationProvider>
                      // </MDBox>
                    ),
                    action: (
                      <MDButton
                        color="error"
                        onClick={async () => {
                          await popUp(deleteSchedule, key);
                        }}
                      >
                        delete
                      </MDButton>
                    ),
                  };
                }),
              ],
              columns: [
                { Header: "title", accessor: "title", align: "center", width: "25%" },
                { Header: "date", accessor: "date", align: "center", width: "20%" },
                { Header: "time", accessor: "time", align: "center", width: "20%" },
                { Header: "", accessor: "--", align: "center" },
                { Header: "action", accessor: "action", align: "center", width: "10%" },
              ],
            }}
            isSorted={false}
            showTotalEntries={false}
            noEndBorder
            // canSearch={true}
            entriesPerPage={{ defaultValue: 1000000, entries: [10, 25, 50, 100, 500] }}
            // pagination={{ variant: "contained", color: sidenavColor }}
          />
        </MDBox>
      </Card>
    </>
  );
};
EventSchedules.propTypes = {
  data: PropTypes.array,
  handleDataChange: PropTypes.func,
  deleteSchedule: PropTypes.func,
};

export default EventSchedules;
