import axios from "axios";
import CatchMessage from "./catchMessage";
import { URL } from "./MainAPILink";

export const GetCountryCodeList = async (access_token, dispatch) => {
  try {
    const response = await axios.get(URL + "auth/country_list", {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });
    console.log(response.data);
    if (response.data.is_success) {
      return response.data.data.list;
    } else {
      return null;
    }
  } catch (err) {
    CatchMessage(err, dispatch);
    return null;
  }
};
